import React, { useEffect, useState } from "react";
import { API } from "../utils/helper";
import { Button, Checkbox, Dialog, Heading, Pane, Select, Spinner, TextInput, Textarea, toaster } from "evergreen-ui";
import { Link } from "react-router-dom";
import { ENCRYTP, VERIFY_TOKEN } from "../utils/decrypter";

const Register = () => {

    const [fullname, set_fullname]                  = useState("")
    const [username, set_username]                  = useState("")
    const [password, set_password]                  = useState("")
    const [confirm_password, set_confirm_password]  = useState("")
    const [email, set_email]                        = useState("")
    const [phone, set_phone]                        = useState("")
    const [type, set_type]                          = useState("Individual")
    const [identical_no, set_identical_no]          = useState("")
    const [address, set_address]                    = useState("")

    const [loading, set_loading]                    = useState(false)
    const [checked, setChecked]                     = useState(true)

    const [isShown, setIsShown]                     = useState(false)
    const [errorBody, setErrorBody]                 = useState("")

    const [select_zipcode, set_select_zipcode]      = useState("")
    const [select_state, set_select_state]          = useState("")
    const state = [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Kuala Lumpur",
        "Labuan",
        "Sabah",
        "Putrajaya"
    ]

    useEffect(() => {


    }, [])

    const __SignUp = async () => {

        set_loading(true)

        if(username === "" || username === null || username === " ") 
        {
            toaster.danger("Username field cannot be empty.")
            set_loading(false)
        }
        else if(password === "" || password === null || password === " ") 
        {
            toaster.danger("Password field cannot be empty.")
            set_loading(false)
        } 
        else if(confirm_password === "" || confirm_password === null || confirm_password === " ") 
        {
            toaster.danger("Confirm password field cannot be empty.")
            set_loading(false)
        }
        else if(password !== confirm_password) 
        {
            toaster.danger("Password and confirm password is not valid.")
            set_loading(false)
        }
        else if(email === "" || email === null || email === " ") 
        {
            toaster.danger("Email field cannot be empty.")
            set_loading(false)
        } 
        else if(phone === "" || phone === null || phone === " ") 
        {
            toaster.danger("Phone field cannot be empty.")
            set_loading(false)
        }  
        else if(type === "" || type === null || type === " ") 
        {
            toaster.danger("Account type field cannot be empty.")
            set_loading(false)
        } 
        else if(type === "Company" && identical_no === "") 
        {
            toaster.danger("Company registration number field cannot be empty.")
            set_loading(false)
        } 
        else if(select_state === "" || select_state === null || select_state === " ") 
        {
            toaster.danger("State field cannot be empty.")
            set_loading(false)
        } 
        else if(select_zipcode === "" || select_zipcode === null || select_zipcode === " ") 
        {
            toaster.danger("Zipcode field cannot be empty.")
            set_loading(false)
        } 
        else if(address === "" || address === null || address === " ") 
        {
            toaster.danger("Account type field cannot be empty.")
            set_loading(false)
        }
        else if(!checked) 
        {
            toaster.danger("Please verify account terms and condition agreement below.")
            set_loading(false)
        }    
        else 
        {

            let json = {
                username: username, 
                password: password,
                confirm_password: confirm_password,
                email: email,
                phone: phone,
                type: type,
                address: address,
                identical_no: identical_no,
                state: select_state,
                zipcode: select_zipcode
            }

            let hash_str = ENCRYTP(json)

            let api = await API("auth/signup", {
                username: username, 
                password: password,
                confirm_password: confirm_password,
                email: email,
                phone: phone,
                type: type,
                address: address,
                identical_no: identical_no,
                state: select_state,
                zipcode: select_zipcode,
                hash: hash_str
            }, "POST")
            console.log("Log Function Register Account : ", api)

            if(api.status_code === 200) 
            {
                let api = await API("auth/login", {
                    username: username, 
                    password: password}, "POST"
                )
                console.log("Log Function Login Account : ", api)

                if(api.status_code === 200) {
                    let get_token = api.data.aT
                    let verify_token = await VERIFY_TOKEN(get_token)

                    window.dispatchEvent(new Event("storage"));
                    window.localStorage.setItem("_aT", get_token)
                    window.localStorage.setItem("_rT", api.data.rT)
                    
                    window.location.href = "/"
                    set_loading(false)
                    
                } else {
                    setErrorBody(api.message)
                    setIsShown(true)
                    set_loading(false)
                }
            } 
            else 
            {
                setErrorBody(api.message)
                setIsShown(true)
                set_loading(false)
            }
        }
    }

    const __GoogleSingIn = async () => {

    }

    return (
        <div className="row login-container" style={{ }}>

            <Dialog
                isShown={isShown}
                title="Login Failed!"
                onCloseComplete={() => setIsShown(false)}
                preventBodyScrolling
                intent="danger"
                confirmLabel="Close"
                onConfirm={() => setIsShown(false)}
                hasCancel={false}
                shouldCloseOnOverlayClick={false}
            >
                {errorBody}
            </Dialog>

            <div className="p-3 col-12" style={{ maxWidth: '540px'}}>
                <div className="flex justify-center items-center">
                    <img src={require("../assets/Logo/NLOGO.png")} width={100} height={70} />
                </div>
                <div className="small-line-height my-4 text-center" style={{ maxWidth: '786px'}}>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Create Account</p>
                    <span className="text-secondary text-xs sm:text-sm">Complete form below to create your E-SERTU account.</span>
                </div>
                <div className="mt-4">
                    <Heading><span className="text-danger">*</span> Account Type</Heading>
                    <Select 
                        className="mt-2 w-100" 
                        onChange={text => set_type(text.target.value)}
                        style={{ fontFamily: 'Poppins, sans-serif'}}
                        size="large"
                        defaultValue={type}
                    >
                        <option style={{ fontFamily: 'Poppins, sans-serif'}}>-- Please Select --</option>
                        <option value={"Individual"} style={{ fontFamily: 'Poppins, sans-serif'}}>Individual</option>
                        <option value={"Company"} style={{ fontFamily: 'Poppins, sans-serif'}}>Company</option>
                    </Select>
                </div>
                {
                    type === "Company" &&
                    <>
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> Company Registration No.</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={string => set_identical_no(string.target.value)} 
                                type="text"
                                name="identical_no"
                                placeholder="A-981263-MD"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </>
                }
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> {type === "Company" ? 'Company Name' : 'Fullname'}</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={string => set_fullname(string.target.value)} 
                                type="text"
                                name="fullname"
                                placeholder="John Doe"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> Username</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={username => set_username(username.target.value)} 
                                type="text"
                                name="username"
                                placeholder="JohnDoe"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> Password</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={password => set_password(password.target.value)} 
                                type="password"
                                name="password"
                                placeholder="••••••••"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> Confirm Password</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={password => set_confirm_password(password.target.value)} 
                                type="password"
                                name="password"
                                placeholder="••••••••"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> Email</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={string => set_email(string.target.value)} 
                                type="text"
                                name="username"
                                placeholder="JohnDoe@email.com"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> No. Phone</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={string => set_phone(string.target.value)} 
                                type="number"
                                name="username"
                                placeholder="0123456789"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> State</Heading>
                            <Select 
                                className="mt-2 w-100" 
                                onChange={text => set_select_state(text.target.value)}
                                style={{ fontFamily: 'Poppins, sans-serif'}}
                                size="large"
                                defaultValue={type}
                            >
                                <option style={{ fontFamily: 'Poppins, sans-serif'}}>-- Please Select --</option>
                                {
                                    state.map((data, index) => 
                                        <option key={index} style={{ fontFamily: 'Poppins, sans-serif'}} value={data}>{data}</option>
                                    )
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="mt-3">
                            <Heading><span className="text-danger">*</span> Zipcode</Heading>
                            <TextInput 
                                className="mt-2 w-100 p-2" 
                                onKeyUp={string => set_select_zipcode(string.target.value)} 
                                type="number"
                                name="zipcode"
                                placeholder="68000"
                                size="large"
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <Heading><span className="text-danger">*</span> Address</Heading>
                    <Textarea
                        className="mt-2 w-100 p-2" 
                        onKeyUp={string => set_address(string.target.value)} 
                        type="text"
                        name="address"
                        placeholder="6th Street of Oxford, Manchester, United Kingdom"
                        size="large"
                        style={{
                            fontFamily: 'Poppins, sans-serif'
                        }}
                    />
                </div>

                <div className="my-3">
                    <div style={{ display: 'flex', alignItems: 'end'}}>
                        <Checkbox
                            style={{
                                fontFamily: 'Poppins, sans-serif'
                            }}
                            fontFamily="Poppins, sans-serif"
                            checked={checked}
                            onChange={e => setChecked(e.target.checked)}
                        />
                        <div className="px-2 small-line-height">
                            <span className="text-200 text-secondary">
                                I understood and agree to the terms and condition of E-SERTU application.
                                Any false information will be take in an action.
                            </span>
                        </div>
                    </div>
                </div>
                {/* <div className="mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Checkbox
                            style={{
                                fontFamily: 'Poppins, sans-serif'
                            }}
                            fontFamily="Poppins, sans-serif"
                            checked={checked}
                            onChange={e => setChecked(e.target.checked)}
                        />
                        <div className="px-2">
                            <span className="text-200 text-scondary">Remember me</span>
                        </div>
                    </div>
                    <div>
                        <Link>
                            <span className="text-200 text-scondary fw-bold">Forgot password?</span>
                        </Link>
                    </div>
                </div> */}
                <div className="mt-5 text-center">
                    <Button disabled={loading} onClick={__SignUp} appearance="primary" intent={loading ? "gray" : "success"} className="w-100 p-3" size="large">
                        {
                            loading ? <Spinner size={16} color="gray" /> : <span>Create Account</span>
                        }
                    </Button>
                </div>
                <div className="mt-2 text-center" style={{ marginTop: 5}}>
                    <Link to={"/"} className="btn btn-default w-100" size="large">
                        {/* <img src="https://img.freepik.com/free-icon/search_318-265146.jpg" style={{ width: 20, height: 20}} /> */}
                        <span className="text-200 text-secondary">Home</span>
                    </Link>
                </div>
                <div className="mt-3 text-center">
                    <span className="text-200 text-secondary">Log in account? Click <Link to={"/login"} className="fw-bold">here</Link> to login.</span>
                </div>
            </div>
        </div> 
    )
}

export default Register;
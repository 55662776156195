import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TopHeader from '../components/TopHeader';
import TailwindBackground from '../components/TailwindBackground';
import { API, BANK_FPX, IMAGE_UPLOADER } from '../utils/helper';
import { Alert, Dialog, FileCard, FileUploader, Heading, Pane, Spinner, toaster } from 'evergreen-ui';

function PayQuotation(props) {

    const state                     = useLocation().state
    const [loading, set_loading]    = useState(false)

    const [isShown, setIsShown]     = useState(false)
    const [isShown2, setIsShown2]   = useState(false)

    const [loading_list_bill, set_loading_list_bill]     = useState(false)
    const [list_quotation_bill, set_list_quotation_bill] = useState([])

    const [bill_amount, set_bill_amount]            = useState(0.00)

    const [select_bill, set_select_bill]            = useState(null)
    const [select_bank_fpx, set_select_bank_fpx]    = useState("")
    const [payor_name, set_payor_name]              = useState("")
    const [payor_email, set_payor_email]            = useState("")
    const [payor_phone, set_payor_phone]            = useState("")

    const [select_bill_code, set_select_bill_code]              = useState("")
    const [select_payment_method, set_select_payment_method]    = useState("")

    const [show_booking_bill, set_show_booking_bill] = useState(1)
    const [show_balance_bill, set_show_balance_bill] = useState(1)
    const [show_fullpay_bill, set_show_fullpay_bill] = useState(1)

    const [files, setFiles]                     = React.useState([])
    const [fileRejections, setFileRejections]   = React.useState([])

    useEffect(() => {
        GET__QUOTATION__BILL()
    }, [state])

    const GET__QUOTATION__BILL = async () => {
        set_loading_list_bill(true)
        let api = await API("bill/get-quotation-bill", { OQFORM_CODE: state.OQFORM.OQFORM_CODE, Q_ID: state.QUOTATION.QUOTATION_CODE }, "POST", true)
        console.log("Log Function Get Quotation Bill : ", api)
        if(api.status_code === 200) {
            
            //set_list_quotation_bill(api.data)
            let new_array = []

            let booking_bill = api.data.filter((data) => data.BILL_PAYMENT_TYPE == "Booking")
            console.log("Log Booking Array : ", booking_bill)
            new_array.push(...booking_bill)

            let balance_bill = api.data.filter((data) => data.BILL_PAYMENT_TYPE == "Balance")
            console.log("Log Booking Array : ", balance_bill)
            if(booking_bill[0]["BILL_STATUS"] === 0) {
                new_array.push(...balance_bill)
            }

            let fullpay_bill = api.data.filter((data) => data.BILL_PAYMENT_TYPE == "Full Payment")
            console.log("Log Booking Array : ", fullpay_bill)
            if(booking_bill[0]["BILL_STATUS"] === 1) {
                new_array.push(...fullpay_bill)
            }

            console.log("Log Function Final Array : ", new_array)
            set_list_quotation_bill(new_array)
        }
    }

    const PAY__QUOTATION__BILL = async (bill_code, bill_payment_method) => {
        
        setIsShown(true)

        if(select_payment_method === "Attachment") 
        {
            let upload_image = await __UploadImage();

            if(upload_image.status) {
                
                let json = {
                    BILL_CODE: select_bill_code,
                    BILL_PAYMENT_METHOD: select_payment_method,
                    BILL_ATTACHMENT_URL: upload_image.url
                }
        
                let api = await API("bill/pay-quotation-bill", json, "POST", true)
                console.log("Log Function Pay Bill : ", api)
        
                if(api.status_code === 200)
                {
                    toaster.success("Request Success!", { description: api.message })
                    window.location.href = "/my-quotation"
                    setIsShown(false)
                } 
                else 
                {
                    setIsShown(false)
                    toaster.danger("Unable To Process Payment!", { description: api.message })
                }

            } else {
                toaster.danger("Unable to upload your image. Complete form below or resize your image.")
                setIsShown(false) 
            }
        }
        else
        {
            let json = {
                BILL_CODE: select_bill_code,
                BILL_PAYMENT_METHOD: select_payment_method,
            }
    
            let api = await API("bill/pay-quotation-bill", json, "POST", true)
            console.log("Log Function Pay Bill : ", api)
    
            if(api.status_code === 200){
                toaster.success("Request Success!", { description: api.message })
                window.location.href = api.data.pay_url + `?billBankID=${select_bank_fpx}`
                setIsShown(false)
            } else {
                setIsShown(false)
                toaster.danger("Unable To Process Payment!", { description: api.message })
            }
        }
    }

    const __UploadImage = async () => {
        if(!files || files.length < 1) {
            toaster.danger("Please upload at least 1 image!")
            return {
                status: false,
                url: ""
            }
        } else {
            let upload = await IMAGE_UPLOADER(files, files.file)
            console.log("RESULT UPLOAD IMAGE : ", upload)
            return upload;
        }
    }

    const SELECT__BILL = (bill_reference_no) => {
        set_select_bill(bill_reference_no)
    }

    const handleChange = React.useCallback((files) => {
        console.log(files)
        setFiles([files[0]])
    }, [])

    const handleRejected    = React.useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove      = React.useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])

    return (
        <div>
            <TopHeader />    
            <div className='mt-[70px]'>

            <Pane>
                <Dialog
                    isShown={isShown}
                    onCloseComplete={() => setIsShown(false)}
                    hasHeader={false}
                    hasFooter={false}
                    preventBodyScrolling={isShown}
                    shouldCloseOnOverlayClick={false}
                >
                    <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
                        <Spinner />
                        <Heading className='mt-3'>Please wait while we are processing your request...</Heading>
                    </Pane>
                </Dialog>
            </Pane>

            <Dialog
            title="Pay Quotation Bill Confirmation"
            intent='danger'
            confirmLabel='Confirm'
            isShown={isShown2}
            onCancel={() => setIsShown2(false)}
            onCloseComplete={() => setIsShown2(false)}
            onConfirm={() => PAY__QUOTATION__BILL()}
            //hasFooter={false}
            >
                <div>
                    <p className='text-sm text-gray-600'>
                        Are you sure to pay this bill? Please click button confirm below to proceed your payment.
                    </p>
                </div>
            </Dialog>

            <TailwindBackground>
                <div>
                    <h1 className='font-bold text-5xl'>Pay Your Quotation.</h1>
                    <span>Please select your bill below and made your payments to your operator.</span>
                </div>
                <div className='mt-4'>
                    <div className='grid grid-cols-12 sm:grid-cols-12 gap-2'>
                        <div className='col-span-12 md:col-span-7 bg-white p-4 shadow-sm rounded-lg'>
                            <p className='font-bold text-lg text-gray-700'>Quotation Detail</p>
                            <div className='mt-3'>
                                <p className='font-bold text-gray-700'>No. {state.QUOTATION.QUOTATION_CODE}</p>
                                <p className='text-base text-sm text-gray-500'>{state.QUOTATION.QUOTATION_DESCRIPTION}</p>
                            </div>
                            {/* <div className='mt-0'>
                                <p className='text-base text-sm text-gray-500'>{state.OQFORM.OQFORM_CODE}</p>
                            </div> */}
                            {/* <div className='mt-3'>
                                <div className='flex justify-between items-center'>
                                    <div>
                                        <p className='font-bold text-gray-700'>Requestor Name</p>
                                    </div>
                                    <div>
                                        <p>{state.OQFORM.QUOTATION_OWNER_NAME}</p>
                                    </div>
                                </div>
                                <div className='flex justify-between items-center mt-1'>
                                    <div>
                                        <p className='font-bold text-gray-700'>Requestor Email</p>
                                    </div>
                                    <div>
                                        <p>{state.OQFORM.QUOTATION_OWNER_EMAIL}</p>
                                    </div>
                                </div>
                                <div className='flex justify-between items-center mt-1'>
                                    <div>
                                        <p className='font-bold text-gray-700'>Requestor Phone</p>
                                    </div>
                                    <div>
                                        <p>{state.OQFORM.QUOTATION_OWNER_PHONE}</p>
                                    </div>
                                </div>
                            </div> */}

                            <div className='mt-3 text-left'>
                                {/* <p className='text-lg font-bold'>Operator's Information</p> */}
                                <div className='grid grid-cols-12 gap-2'>
                                    <div className='col-span-4'>
                                        <img src={state.OQFORM.OPERATOR_LOGO} className='w-[180px] h-[180px] object-contain' />
                                    </div>
                                    <div className='col-span-8'>
                                        <p className='text-lg font-bold'>{state.OQFORM.OPERATOR_NAME}</p>
                                        <p className='text-md'>{state.OQFORM.OPERATOR_EMAIL}</p>
                                        <p className='text-md'>{state.OQFORM.OPERATOR_PHONE}</p>
                                        <p className='mt-3 text-sm text-gray-400 w-[300px]'>{state.OQFORM.OPERATOR_ADDRESS}</p>
                                    </div>
                                </div>
                                {/* <div className='mt-3 d-flex items-center justify-center text-center'>
                                    <div className='d-flex flex-column items-center justify-center'>
                                        
                                        
                                    </div>
                                </div> */}
                            </div>
                            {/* <p className='flex-1'>
                                {JSON.stringify(state)}
                            </p> */}
                        </div>
                        <div className='col-span-12 md:col-span-5'>
                            <div className='p-4 shadow-sm bg-white rounded-lg'>
                                <p className='font-bold text-lg text-gray-700'>Payment Information</p>
                                <div className='mt-3'>

                                    <div className='flex justify-between items-center'>
                                        <div>
                                            <p className='font-bold text-gray-700'>Subtotal</p>
                                        </div>
                                        <div>
                                            <p>RM {parseFloat(bill_amount).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className='mt-1 flex justify-between items-center'>
                                        <div>
                                            <p className='font-bold text-gray-700'>SST (6%)</p>
                                        </div>
                                        <div>
                                            <p>RM {parseFloat(0.00).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className='mt-1 flex justify-between items-center'>
                                        <div>
                                            <p className='font-bold text-gray-700'>Discount(0%)</p>
                                        </div>
                                        <div>
                                            <p>RM {parseFloat(0.00).toFixed(2)}</p>
                                        </div>
                                    </div>
                                    <div className='mt-3 flex justify-between items-center border-t-[1px] border-gray-300 pt-3'>
                                        <div>
                                            <p className='font-bold text-gray-700'>Total</p>
                                        </div>
                                        <div>
                                            <p className='font-bold text-emerald-600 text-lg'>RM {parseFloat(bill_amount).toFixed(2)}</p>
                                        </div>
                                    </div>

                                    <div className='b-0 w-100 mt-3'>
                                        {
                                            !select_bill || (select_bill && select_payment_method === "FPX" && !select_bank_fpx) ?
                                            <button disabled className='w-100 bg-gray-400 px-3 py-3 shadow rounded-lg text-white text-sm font-bold'>Pay Quotation Bill</button> :
                                            <button onClick={() => setIsShown2(true)} className='w-100 bg-emerald-600 px-3 py-3 shadow rounded-lg text-white text-sm font-bold'>Pay Quotation Bill</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                {
                    list_quotation_bill.length > 0 ?
                    <>
                    <div className='mt-2'>
                        <div className='grid grid-cols-12 gap-2'>
                            <div className='col-span-12 md:col-span-7 bg-white p-4 shadow-sm rounded-lg leading-2'>
                                <p className='font-bold text-lg text-gray-700'>Select Quotation Bill</p>
                                <span className='text-sm text-gray-500'>Please choose your quotation bill below by click any of the bills below.</span>

                                <div className='mt-3'>
                                    <div className='flex gap-2'>
                                        {
                                            list_quotation_bill.length > 0 && list_quotation_bill.map((data, index) => 
                                            <button 
                                            key={index}
                                            disabled={data.BILL_STATUS != 1 ? true : false}
                                            onClick={() => {
                                                set_bill_amount(data.BILL_AMOUNT)
                                                set_select_bill_code(data.BILL_INCOIVE_NO)
                                                set_select_payment_method(data.BILL_PAYMENT_METHOD)
                                                SELECT__BILL(data.BILL_INCOIVE_NO)
                                            }}
                                            type='button'
                                            className={`w-[150px] h-[120px] rounded-lg shadow-sm ${select_bill === data.BILL_INCOIVE_NO ? 'bg-gray-200' : data.BILL_STATUS === 0 ? 'bg-gray-300' : 'bg-white'} border-[1.5px] border-dashed border-gray-400`}
                                            >
                                                <p className='text-xs text-gray-400'>{data.BILL_PAYMENT_TYPE} Biil</p>
                                                <p className='text-md font-bold text-emerald-600'>RM {parseFloat(data.BILL_AMOUNT).toFixed(2)}</p>
                                                {
                                                    data.BILL_STATUS === 0 && <p className='text-xs text-red-600'>(PAID)</p>
                                                }
                                            </button>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <div className='grid grid-cols-12 gap-2'>
                            <div className='col-span-12 md:col-span-7 bg-white p-4 shadow-sm rounded-lg leading-2'>
                                <p className='font-bold text-lg text-gray-700'>Select Payment Method</p>
                                <span className='text-sm text-gray-500'>Please choose your payment method to make payment on your selected bill.</span>

                                <div className='mt-3'>
                                    {
                                        list_quotation_bill.length > 0 && !select_bill && 
                                        <button disabled className='px-3 py-2 border-[1px] border rounded text-sm bg-gray-100 w-100'>
                                            -- Please Select Bill --
                                        </button>
                                    }

                                    {
                                        list_quotation_bill.length > 0 && select_bill && list_quotation_bill.map((data, index) => data.BILL_INCOIVE_NO === select_bill &&
                                        <div>
                                            {
                                                data.BILL_PAYMENT_METHOD === "FPX" && 
                                                <div className='grid grid-cols-3 gap-1'>
                                                    {
                                                        BANK_FPX.length > 0 && BANK_FPX.map((bank, index) => 
                                                        <button 
                                                        onClick={() => set_select_bank_fpx(bank.CODE)}
                                                        className={`rounded w-100 h-[120px] ${select_bank_fpx === bank.CODE ? 'bg-gray-200' : 'bg-white'} border-2 border-dashed d-flex flex-column justify-center items-center`}
                                                        >
                                                            <img src={`https://toyyibpay.com/asset/img/logobank/${bank.CODE}.png`} className='w-[70px] h-[50px] object-contain' />
                                                            <p className='text-xs text-gray-500'>{bank.NAME}</p>
                                                        </button>
                                                        )
                                                    }
                                                </div>
                                                
                                            }

                                            {
                                                data.BILL_PAYMENT_METHOD === "Attachment" &&
                                                <FileUploader
                                                    maxSizeInBytes={15 * 1024 * 1024}
                                                    maxFiles={1}
                                                    onChange={handleChange}
                                                    onRejected={handleRejected}
                                                    title='Upload your attachment here'
                                                    description="Maximum file size up to 10mb and only .pdf, .png, .jpeg and .jpg file."
                                                    renderFile={(file) => {
                                                    const { name, size, type } = file
                                                    const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                                    const { message } = fileRejection || {}
                                                    return (
                                                        <FileCard
                                                        key={name}
                                                        isInvalid={fileRejection != null}
                                                        name={name}
                                                        onRemove={handleRemove}
                                                        sizeInBytes={size}
                                                        type={type}
                                                        validationMessage={message}
                                                        />
                                                    )
                                                    }}
                                                    values={files}
                                                />
                                            }
                                        </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div> 
                    </> :
                    <div className='mt-2'>
                        <div className='grid grid-cols-12 gap-2'>
                            <div className='col-span-12 md:col-span-7 bg-white p-4 shadow-sm rounded-lg'>
                                <Alert 
                                    intent='warning'
                                    title="**Pending Review"
                                >
                                    <p className='mt-1 text-xs text-gray-500 leading-2'>Please wait while your request is being review by our operator. You will get notification once our operator has ready your bills.</p>
                                </Alert>
                            </div>
                        </div>
                    </div> 
                }                 
            </TailwindBackground>   
            </div> 
        </div>
    );
}

export default PayQuotation;
import React, { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Heart, Image, Home, Settings } from 'react-feather';

const AuthLayout = ({ children }) => {
    const menu = [
        {
            _id: 1,
            title: 'Features',
            icon: <Heart size={20} className='icon-primary' />,
            path: "/favourite"
        },
        {
            _id: 2,
            title: 'Media',
            icon: <Image size={20} className='icon-primary' />,
            path: "/media"
        },
        {
            _id: 3,
            title: 'Home',
            icon: <Home size={20} className='icon-primary' />,
            path: "/"
        },
        {
            _id: 4,
            title: 'Settings',
            icon: <Settings size={20} className='icon-primary' />,
            path: "/setting"
        },
    ]
  
    return (
        <div>
            {/* Outlet is wrapped inside a div with flex: 1 to occupy remaining height */}
            <Suspense>
                <Outlet />
            </Suspense>
        </div>
    );
  };

export default AuthLayout;

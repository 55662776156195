import { color } from "../constant";
import { HomeIcon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

export default function Footer({ bg = "#45526e" }) {
    return (
        <div className="mt-5" style={{ maxWidth: '1140px', margin: 'auto'}}>
            <footer className="text-center text-lg-start text-white" style={{ backgroundColor: bg}}>
                <div className="container p-4 pb-0">
                    <section className="">
                        <div className="row">
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            <p className="mb-4 text-lg font-bold" style={{ color: bg !== "" ? color.black : color.white}}>e-sertu</p>
                            <p className="text-sm" style={{ color: bg === "transparent" ? color.black : color.white}}>Preferred sertu solution platform.</p>
                        </div>

                        <hr className="w-100 clearfix d-md-none" />

                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 text-lg font-bold" style={{ color: bg !== "" ? color.black : color.white}}>Services</h6>
                            <div className="small-line-height">
                            <p><a className="text-sm" style={{ color: bg === "transparent" ? color.black : color.white}}>Sertu Cleansing</a></p>
                            <p><a className="text-sm" style={{ color: bg === "transparent" ? color.black : color.white}}>Sertu Consultancy</a></p>
                            <p><a className="text-sm" style={{ color: bg === "transparent" ? color.black : color.white}}>Sertu Training</a></p>
                            <p><a className="text-sm" style={{ color: bg === "transparent" ? color.black : color.white}}>Sertu Competency</a></p>
                            </div>
                        </div>

                        <hr className="w-100 clearfix d-md-none" />

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 text-lg font-bold" style={{ color: bg !== "" ? color.black : color.white}}>Useful links</h6>
                            <div className="small-line-height">
                            <p><a href="https://siieta.com" target="_blank" className="text-sm underline" style={{ color: bg === "transparent" ? color.black : color.white}}>SIIETA</a></p>
                            <p><a href="https://dagangtek.com" target="_blank" className="text-sm underline" style={{ color: bg === "transparent" ? color.black : color.white}}>DagangTEK</a></p>
                            </div>
                        </div>

                        <hr className="w-100 clearfix d-md-none" />

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 text-lg font-bold" style={{ color: bg !== "" ? color.black : color.white}}>Contact</h6>
                            <div className="">
                                <p className="text-sm flex" style={{ color: bg === "transparent" ? color.black : color.white}}>
                                    <HomeIcon className="mr-2" width={16} /> T2-L8-1, Level 8, IOI Resort City
                                </p>
                                <p className="text-sm flex" style={{ color: bg === "transparent" ? color.black : color.white}}>
                                    <EnvelopeIcon className="mr-2" width={16} /> info@e-sertu.com
                                </p>
                                <p className="text-sm flex" style={{ color: bg === "transparent" ? color.black : color.white}}>
                                    <PhoneIcon className="mr-2" width={16} /> +603-8682 1933
                                </p>
                            </div>
                        </div>
                        </div>
                    </section>

                    <hr className="my-3" />

                    <section className="p-3 pt-0 d-flex justify-content-center align-items-center">
                        <div className="row d-flex justify-content-center align-items-center w-100">
                            <div className="col-md-7 col-lg-8 text-center  w-100">
                                <div className="p-3 text-200 text-center" style={{ color: bg !== "" ? color.black : color.white}}>
                                Copyright © {new Date().getFullYear()} <a className="" href="/">e-sertu.com</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </footer>
        </div>
    )
}
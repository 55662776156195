import React, { Suspense, useEffect } from 'react';
import { Link, Outlet, useNavigation } from 'react-router-dom';
import { CHECK_SESSION } from '../utils/decrypter';
import GlobalLoading from '../components/GlobalLoading';

const PrivateLayout = ({ children }) => {

    useEffect(() => {
        if(CHECK_SESSION()) {
            
        } else {
            window.location.href = "/login"
        }
    }, [window.location.pathname])
    return (
        <div>
            {/* Outlet is wrapped inside a div with flex: 1 to occupy remaining height */}
            <Suspense fallback={<GlobalLoading />}>
                <Outlet />
            </Suspense>
        </div>
    );
  };

export default PrivateLayout;

export const color = {
    primary: 'rgb(4,95,105)',
    secondary: '',
    white: '#fafafa',
    black: '#171717',
    success: '#15803d',
    warning: '#f59e0b',
    danger: '#b91c1c',
    lightgray: '#f1f5f9',
    gray: '#64748b',
    darkgray: '#334155',
    info: '#0284c7'
}

export const data = {
    sertu_service: [
        { id: 1, name: 'Sertu', value: 'sertu' },
        { id: 2, name: 'Sertu Consultation', value: 'consultation' },
        { id: 3, name: 'Sertu Training', value: 'training' },
        { id: 4, name: 'Pre-Audit', value: 'audit' }
    ],
    sertu_categories: 
    [
        { id: 1, name: 'House', value: 'House' },
        { id: 2, name: 'Vehicle', value: 'Vehicle' },
        { id: 3, name: 'Container', value: 'Container' },
        { id: 4, name: 'Kitchen', value: 'Kitchen' },
        { id: 5, name: 'Vessel', value: 'Vessel' },
        { id: 6, name: 'Restaurant', value: 'Restaurant' },
        { id: 7, name: 'Warehouse', value: 'Warehouse' },
        { id: 8, name: 'Factory', value: 'Factory' },
        { id: 9, name: 'Others', value: 'Others' }
    ],
    dashboard: {
        sample: [
            {
                _id: 1,
                image: require("./assets/Banner/Banner1.png")
            },
            {
                _id: 2,
                image: require("./assets/Banner/Banner1.png")
            },
            {
                _id: 3,
                image: require("./assets/Banner/banner3.avif")
            },
            {
                _id: 4,
                image: require("./assets/Banner/banner4.jpeg")
            },
            {
                _id: 5,
                image: require("./assets/Banner/banner5.avif")
            },
            {
                _id: 6,
                image: require("./assets/Banner/banner6.jpeg")
            }
        ],
        promotion: [
            {
                _id: 1,
                image: require("./assets/Banner/b1.png")
            },
            {
                _id: 2,
                image: require("./assets/Banner/b2.png")
            },
            {
                _id: 3,
                image: require("./assets/Banner/b3.png")
            },
            {
                _id: 4,
                image: require("./assets/Banner/b4.png")
            }
        ],
        service_type: [
            {
                id: 1,
                name: 'House',
                image: "https://www.cleaningservicesmalaysia.com/wp-content/uploads/2023/06/House-Cleaning-In-Petaling-Jaya.jpg"
            },
            {
                id: 2,
                name: 'Vehicle',
                image: "https://www.cardinus.com/wp-content/uploads/2020/07/shutterstock_1674052525.jpg"
            },
            {
                id: 3,
                name: 'Container',
                image: "https://www.vsnb.com/sites/default/files/inline-images/blog%20and%20linkedin%204.jpg"
            },
            {
                id: 4,
                name: 'Kitchen',
                image: "https://cleaningspaces.net/wp-content/uploads/2018/11/cleaning-kitchen.jpeg"
            },
            {
                id: 5,
                name: 'Vessel',
                image: "https://media.istockphoto.com/id/96655541/photo/man-in-chemical-suit.jpg?s=612x612&w=0&k=20&c=te-OWlcMTv4cUA0jOtPnjhZkTwIlHsd9byh6S64Uxzg="
            },
            {
                id: 9,
                name: 'Restaurant',
                image: "https://media.istockphoto.com/id/1191665504/photo/because-you-cant-work-in-a-messy-place.jpg?s=612x612&w=0&k=20&c=lLSFw5tvUKlUus8oDlC0CtPLHtr_Rpooxv4K5zG85_Q="
            },
            {
                id: 6,
                name: 'Warehouse',
                image: "https://washh.com/wp-content/uploads/2021/03/The-Dos-And-Donts-Of-Warehouse-Cleaning.jpeg"
            },
            {
                id: 7,
                name: 'Factory',
                image: "https://images.squarespace-cdn.com/content/v1/5e15c38241122575bc7f9995/1578993818411-T1VVED0PI6ZZZMDPGX4Q/Factory+Cleaning.jpg"
            },
            {
                id: 8,
                name: 'Others',
                image: "https://recomn-production2.s3.ca-central-1.amazonaws.com/category-tile-images/plaster-ceiling-min.jpg"
            },
        ]
    },
    operators: [
        {
            id: 1,
            operator_name: "Global Dibagh Sdn. Bhd.",
            operator_description: `
            GLOBAL DIBAGH SDN. BHD. was incorporated on 2011-05-12 in Malaysia with registration number of 0944251P. GLOBAL DIBAGH SDN. BHD.'s business includes PROPERTY DEVELOPEMTN, CONSTRUCTION AND SAMAQ; GENERAL TRADING
            Get an accurate picture of a customer's financial status
            with a credit report for a business. Our solutions help
            you minimise risk, increase sales and improve business
            performance.`,
            operator_image: 'https://sertu.com.my/wp-content/uploads/2020/04/dibahglogo-250x135.jpg',
            operator_cover_image: 'https://media.angi.com/s3fs-public/man-home-cleaning-service-vacuuming.jpg',
            operator_rating: 4,
            customers: 281
        },
        {
            id: 1,
            operator_name: "Artiq (M) Sdn. Bhd.",
            operator_description: `
            Established in the year 2015, Artiq Clean brand own by ARTIQ (M) SDN BHD (Formerly know as Artiq Pro Clean Sdn Bhd offers you the strength and expertise of our cleaning solutions and services, with the state of the art cleaning technology. Backed up by this experience, we can confidently promise unparalleled solutions to meet our customer’s specific requirements, from general cleaning, to specialized waste management and hospitality services.
            Among our valued clients are some of the Klang Valley and State of Selangor Darul Ehsan’s notable commercial and industrial companies. Our company also serves individual households, which we serve with equal dedication, expertise and care.`,
            operator_image: 'https://artiqclean.com/wp-content/uploads/2021/08/logo-artiq.png',
            operator_cover_image: 'https://artiqclean.com/wp-content/uploads/2021/08/18588831_1837099363207612_2231454660894323548_o.jpg',
            operator_rating: 4,
            customers: 762
        },
    ],
    service_explaination: [
        {
            id: 1,
            title: 'Sertu Cleansing Services',
            description: `As Halal stands for Quality, we provide full range of Sertu process which tailor to customer’s technical & QAS requirement with our skills and competent Juru-Sertu. Preserving authentication and time efficient will be our motto while delivering our responsibility as well Halal authority satisfaction.`
        },
        {
            id: 2,
            title: 'Sertu QAS Advisory & Consultancy',
            description: `We offers a one-stop professional Sertu consultation to businesses and organizations on the inner and outer workings of the Halal ecosystem. Focusing on end-to-end solutions, we integrate HAS requirements when working with our partners as they solidify their halal businessjourney.`
        },
        {
            id: 3,
            title: 'Sertu Competency Training',
            description: `In line with the aspirations of Halal policymakers, we offer a comprehensive Sertu training module which made from our state of the art experience in a variety of farm -to -table industry applications which response to the requirements and compliance set out in MHMS 2020.`
        },
        {
            id: 4,
            title: 'Sertu Pre-Audit',
            description: `We offers a one-stop professional Sertu consultation to businesses and organizations on the inner and outer workings of the Halal ecosystem. Focusing on end-to-end solutions, we integrate HAS requirements when working with our partners as they solidify their halal businessjourney.`
        },
    ],
    packages: [
        {
            id: 1,
            package_name: 'Sertu Basic',
            package_price: 250,
            package_description: 'Suits for small house like 22 x 75 square feet terrace house including your garage and backyards.',
            package_items: [
                { item: 'Less than 22 x 80 sqft houses.'},
                { item: '2 hours completion'},
                { item: 'Living Room'},
                { item: 'Rooms'},
                { item: 'Toilets'},
                { item: 'Kitchen'}
            ]
        },
        {
            id: 2,
            package_name: 'Sertu Pro',
            package_price: 500,
            package_description: 'Suits for medium-large house like 30 x 80 square feet terrace house such as Semi-D house, town house.',
            package_items: [
                { item: 'Less than 30 x 80 sqft houses.'},
                { item: '4 hours completion'},
                { item: 'Living Room'},
                { item: 'Rooms'},
                { item: 'Toilets'},
                { item: 'Kitchen'},
                { item: 'Backyards'},
                { item: 'Garage'}
            ]
        },
        {
            id: 3,
            package_name: 'Sertu Premium',
            package_price: 999,
            package_description: 'A throughly clean home really benefits young children, asthma patients and homeowners who are sensitive to dust and allergens.',
            package_items: [
                { item: 'Less than 30 x 80 sqft houses.'},
                { item: '6 hours completion'},
                { item: 'Living Room'},
                { item: 'Rooms'},
                { item: 'Toilets'},
                { item: 'Kitchen'},
                { item: 'Backyards'},
                { item: 'Garage'},
                { item: 'Special tools included'},
                { item: 'Include sanitizing for all areas'}
            ]
        }
    ]
}
import React from "react";
import * as animationData from '../assets/Lottie/loading.json'
import Lottie from "react-lottie"

export default function GlobalLoading() {

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Lottie
                style={{
                    height: '300px',
                    width: '300px'
                }}
                options={defaultOptions}
                height={200}
                width={200}
                isStopped={false}
                isPaused={false}
            />
        </div>
    )
}
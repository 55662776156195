import React, { useEffect, useMemo, useState } from 'react';
import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';
import { GET_DATA } from '../utils/decrypter';
import { Button, Dialog, DocumentOpenIcon, EditIcon, Icon, Link, Pagination, Pane, PlusIcon, Select, Spinner, StatusIndicator, Switch, Table, TextInput, Textarea, toaster } from 'evergreen-ui';
import { color } from '../constant';
import { useNavigate } from 'react-router-dom';
import { API } from '../utils/helper';

const Setting = () => {

    let navigate = useNavigate()

    useEffect(() => {
        set_user_information(GET_DATA())
    }, [])

    const [user_information, set_user_information]      = useState(null)
    const [loading_api, set_loading_api]                = useState(true)

    const [ACCOUNT_USERNAME , SET_ACCOUNT_USERNAME]     = useState(GET_DATA().ACCOUNT_USERNAME ? GET_DATA().ACCOUNT_USERNAME : "")
    const [ACCOUNT_NAME , SET_ACCOUNT_NAME]             = useState(GET_DATA().ACCOUNT_NAME ? GET_DATA().ACCOUNT_NAME : "")
    const [ACCOUNT_SERCRET_KEY, SET_ACCOUNT_SECRET_KEY] = useState(GET_DATA().ACCOUNT_SERCRET_KEY ? GET_DATA().ACCOUNT_SERCRET_KEY : "")
    const [ACCOUNT_TYPE , SET_ACCOUNT_TYPE]             = useState(GET_DATA().ACCOUNT_TYPE ? GET_DATA().ACCOUNT_TYPE : "")
    const [ACCOUNT_EMAIL , SET_ACCOUNT_EMAIL]           = useState(GET_DATA().ACCOUNT_EMAIL ? GET_DATA().ACCOUNT_EMAIL : "")
    const [ACCOUNT_PHONE , SET_ACCOUNT_PHONE]           = useState(GET_DATA().ACCOUNT_PHONE ? GET_DATA().ACCOUNT_PHONE : "")
    const [ACCOUNT_COUNTRY , SET_ACCOUNT_COUNTRY]       = useState(GET_DATA().ACCOUNT_COUNTRY ? GET_DATA().ACCOUNT_COUNTRY : "")
    const [ACCOUNT_STATE , SET_ACCOUNT_STATE]           = useState(GET_DATA().ACCOUNT_STATE ? GET_DATA().ACCOUNT_STATE : "")
    const [ACCOUNT_ADDRESS , SET_ACCOUNT_ADDRESS]       = useState(GET_DATA().ACCOUNT_ADDRESS ? GET_DATA().ACCOUNT_ADDRESS : "")
    const [ACCOUNT_IMAGE, SET_ACCOUNT_IMAGE]            = useState(GET_DATA().ACCOUNT_IMAGE ? GET_DATA().ACCOUNT_IMAGE : "")

    const [active_menu, set_active_menu]                = useState("Personal")
    const [menu_button, set_menu_button]                = useState(["Personal", "My Quotation"])

    const [PIC_NAME, SET_PIC_NAME]                      = useState("")
    const [PIC_DESIGNATION, SET_PIC_DESIGNATION]        = useState("")
    const [COMPANY_ADDRESS, SET_COMPANY_ADDRESS]        = useState("")
    const [SELECT_ACCOUNT_TYPE, SET_SELECT_ACCOUNT_TYPE] = useState(ACCOUNT_TYPE)

    const [NEW_ADDRESS, SET_NEW_ADDRESS]                = useState("")

    const [NOTIFICATION, SET_NOTIFICATION]              = useState(true)
    const [SHOW_MODAL, SET_SHOW_MODAL]                  = useState(false)

    const __ADD_NEW_ADDRESS = async () => {
        if(NEW_ADDRESS === null || NEW_ADDRESS === "" || NEW_ADDRESS === " ") 
        {
            toaster.danger("New address cannot be empty.", { id: 'forbidden-action' })
        } else {

        }
    }

    const [list_quotation, set_list_quotation]  = useState([])
    useMemo(async () => {
        set_loading_api(true)
        let api = await API("quotation/list", {}, "POST", true)
        if(api.status_code === 200) {
            set_list_quotation(api.data)
            set_loading_api(false)
        } else {
            set_list_quotation([])
            set_loading_api(false)
        }
    }, [])

    const NavigateRoute = (data) => {
        navigate(`/quotation/a=${btoa(data.QUOTATION_CODE)}`, { state: data })
    }
    
    return (
        <div>
            <TopHeader />
            <div style={{ marginTop: 70}}>

                <Dialog 
                    isShown={SHOW_MODAL} 
                    onCancel={() => SET_SHOW_MODAL(false)} 
                    onCloseComplete={() => SET_SHOW_MODAL(false)}
                    confirmLabel='Submit'
                    title="New Address"
                    onConfirm={__ADD_NEW_ADDRESS}
                >
                    <div className='row'>
                        <div className='col-12'>
                            <Textarea 
                                defaultValue={NEW_ADDRESS} 
                                onKeyUp={text => SET_NEW_ADDRESS(text.target.value)} 
                                placeholder='Enter new address here...'
                                style={{
                                    fontFamily: 'Poppins, sans-serif'
                                }}
                            >

                            </Textarea>
                        </div>
                    </div>
                </Dialog>

                <section className='p-3' style={{ height: '100%', overflow: 'scroll'}}>
                    <div className='div-container row mt-3'>
                        <div className='col-12 small-line-height'>
                            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl">Settings</p>
                            <span className='mt-6 text-sm leading-8'>Edit your information, change password, see your quotations and etc here.</span>
                        </div>

                        <div>
                            <div className='row mt-3'>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-8'>
                                    {
                                        menu_button.map((item, index) =>
                                            <Button 
                                                appearance=''
                                                key={index} 
                                                className={`mx-1 ${item === active_menu ? "cbtn-active" : "cbtn"}`}
                                                onClick={() => set_active_menu(item)}
                                            >
                                                <span>{item}</span>
                                                {/* <span style={{ marginLeft: 2}}><StatusIndicator color="danger"></StatusIndicator></span> */}
                                            </Button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            active_menu === "Personal" && 
                            <>
                                <div className='row mt-4' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-8'>
                                        <div className='card shadow-sm p-4'>
                                            <div>
                                                <h6>Personal Information</h6>
                                            </div>
                                            <div className='mt-3'>
                                                <div>
                                                    <h6 className='text-200'>Username</h6>
                                                    <TextInput defaultValue={ACCOUNT_USERNAME} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div>
                                                    <h6 className='text-200'>Name</h6>
                                                    <TextInput defaultValue={ACCOUNT_NAME} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div>
                                                    <h6 className='text-200'>Account Type</h6>
                                                    <TextInput defaultValue={ACCOUNT_TYPE} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div>
                                                    <h6 className='text-200'>Email</h6>
                                                    <TextInput defaultValue={ACCOUNT_EMAIL} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div>
                                                    <h6 className='text-200'>No. Telephone</h6>
                                                    <TextInput defaultValue={ACCOUNT_PHONE} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-12 col-sm-12 col-md-6'>
                                                    <div>
                                                        <h6 className='text-200'>Country</h6>
                                                        <TextInput defaultValue={ACCOUNT_COUNTRY} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                    </div>
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-6'>
                                                    <div>
                                                        <h6 className='text-200'>State / Province</h6>
                                                        <TextInput defaultValue={ACCOUNT_STATE} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <div>
                                                    <h6 className='text-200'>Default Address</h6>
                                                    <Textarea defaultValue={ACCOUNT_ADDRESS} disabled className='w-100 text-200' style={{ fontFamily: 'Poppins, sans-serif'}} />
                                                </div>
                                            </div>
                                            {/* {JSON.stringify(GET_DATA())} */}
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-4'>
                                        <div className='card shadow-sm p-3'>
                                            <div className='text-center'>
                                                <h6>Change Profile</h6>
                                            </div>
                                            <div className='mt-3 d-flex align-item-center justify-content-center'>
                                                {
                                                    <div 
                                                    className='d-flex align-item-center justify-content-center'
                                                    style={{ 
                                                        border: '1px solid #f3f4f6', 
                                                        padding: '30px', 
                                                        borderRadius: 100
                                                    }}>
                                                        <img src={ ACCOUNT_IMAGE ? ACCOUNT_IMAGE : 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png'} style={{ width: 70, height: 70}} />
                                                    </div>
                                                }
                                            </div>
                                            <div className='mt-3 text-center small-line-height'>
                                                <h6>{ACCOUNT_NAME}</h6>
                                                <span className='text-200 text-secondary'>{ACCOUNT_EMAIL}</span><br/>
                                                <span className='text-200 text-secondary'>{ACCOUNT_PHONE}</span>
                                            </div>
                                            <div className='mt-3 d-flex align-item-center justify-content-center'>
                                                <Button appearance='' className='cbtn'>Edit<EditIcon color={color.gray} size={12} style={{ marginLeft: 3}} /></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-3' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-8'>
                                        <div className='card shadow-sm p-4'>
                                            <div>
                                                <h6>Account Configuration</h6>
                                            </div>
                                            <div className='mt-3'>
                                                <h6 className='text-200'>Account Type</h6>
                                                <Select 
                                                    defaultValue={SELECT_ACCOUNT_TYPE} 
                                                    onChange={account_type => SET_SELECT_ACCOUNT_TYPE(account_type.target.value)}
                                                    className='text-200 w-100' 
                                                    style={{ fontFamily: "Poppins, sans-serif"}}
                                                >
                                                    <option value={"Individual"} className='text-200'><span className='text-200' style={{ fontFamily: 'Poppins' }}>Individual</span></option>
                                                    <option value={"Company"} className='text-200'><span className='text-200' style={{ fontFamily: 'Poppins' }}>Company</span></option>
                                                </Select>
                                            </div>
                                            {
                                                SELECT_ACCOUNT_TYPE === "Company" &&
                                                <>
                                                    <div className='mt-3'>
                                                        <div className='row'>
                                                            <div className='col-12 col-sm-12 col-md-6'>
                                                                <div>
                                                                    <h6 className='text-200'>Person-In-Charge Name</h6>
                                                                    <TextInput defaultValue={PIC_NAME} className='w-100 text-200' onKeyUp={(string) => SET_PIC_NAME(string.target.value)} placeholder='Enter your name...' />
                                                                </div>
                                                            </div>
                                                            <div className='col-12 col-sm-12 col-md-6'>
                                                                <div>
                                                                    <h6 className='text-200'>Designation</h6>
                                                                    <TextInput defaultValue={PIC_DESIGNATION} className='w-100 text-200' onKeyUp={(string) => SET_PIC_DESIGNATION(string.target.value)} placeholder='Enter your designation...'/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-12'>
                                                                <h6 className='text-200'>Company Address</h6>
                                                                <Textarea 
                                                                    defaultValue={COMPANY_ADDRESS} 
                                                                    onKeyUp={(string) => SET_COMPANY_ADDRESS(string.target.value)} 
                                                                    className='w-100 text-200'
                                                                    placeholder='Enter your company address here...'
                                                                ></Textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-3' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-8'>
                                        <div className='card shadow-sm p-4'>
                                            <div>
                                                <h6>Address</h6>
                                            </div>
                                            <div className='mt-3 d-flex'>
                                                <div 
                                                className='text-center small-line-height'
                                                style={{
                                                    height: 150,
                                                    width: 250,
                                                    borderStyle: 'dashed',  
                                                    borderRadius: 12,
                                                    border: '2px dashed lightgray',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: 12
                                                }}>
                                                    <div>
                                                        <h6 className='text-200 icon-primary'>Default</h6>
                                                    </div>
                                                    <div>
                                                        <span className='text-150 text-secondary'>
                                                            {ACCOUNT_ADDRESS}
                                                        </span>
                                                    </div>
                                                </div>
                                                <Pane 
                                                onClick={() => SET_SHOW_MODAL(true)}
                                                className='text-center small-line-height' 
                                                style={{
                                                    marginLeft: 7,  
                                                    height: 150,
                                                    width: 250,
                                                    borderStyle: 'dashed',  
                                                    borderRadius: 12,
                                                    border: '2px dashed lightgray',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: 12
                                                }}>
                                                    <div>
                                                        <PlusIcon size={16} color='gray' />
                                                    </div>
                                                    <div>
                                                        <span className='text-150 text-secondary'>Click to add new</span>
                                                    </div>
                                                </Pane>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mt-3' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-8'>
                                        <div className='card shadow-sm p-4'>
                                            <div>
                                                <h6>Notifications</h6>
                                            </div>
                                            <div className='mt-3'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <div className='fw-bold small-line-height'>
                                                        <span className='text-200'>Email</span><br />
                                                        <span className='fw-normal text-150 text-secondary'>{ACCOUNT_EMAIL}</span>
                                                    </div>
                                                    <div>
                                                        <Switch checked={NOTIFICATION} onChange={e => 
                                                        {
                                                            toaster.notify(JSON.stringify(e.target.checked))
                                                            SET_NOTIFICATION(e.target.checked)} 
                                                        }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            active_menu === "My Quotation" && 
                            <>
                                <div className='row mt-4' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-12'>
                                        <div className='card shadow-sm p-4' style={{ transition: '0.5s'}}>
                                            <div>
                                                <h6>My Quotation</h6>
                                            </div>
                                            <div>
                                                <div className='hide-on-mobile mt-3'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center'>
                                                            <Button className='mx-1 cbtn' appearance='' backgroundColor='#FFB020' color={color.white}>In Process</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='success'>Approved</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='danger'>Rejected</Button>
                                                        </div>
                                                        <div>
                                                            <TextInput 
                                                                placeholder='Search'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-12'>
                                                            <Table>
                                                                <Table.Head>
                                                                    <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0}>No.</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"60%"} flexShrink={0} flexGrow={0}>Quotation</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"20%"} flexShrink={0} flexGrow={0}>Date Created</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0} className='text-center'>Action</Table.TextHeaderCell>
                                                                </Table.Head>
                                                                <Table.Body>
                                                                    {
                                                                        loading_api && 
                                                                        <Table.Row>
                                                                            <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                                <Spinner size={24} color={color.gray} />
                                                                            </Table.TextCell>
                                                                        </Table.Row>
                                                                    }

                                                                    {
                                                                        loading_api === false && list_quotation.length < 1 &&
                                                                        <Table.Row>
                                                                            <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                                <span>No data found.</span>
                                                                            </Table.TextCell>
                                                                        </Table.Row>
                                                                    }

                                                                    {
                                                                        loading_api === false && list_quotation.length > 0 && list_quotation.map((data, index) =>
                                                                        <Table.Row 
                                                                            onClick={() => NavigateRoute(data)} 
                                                                            key={index}
                                                                        >
                                                                            <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                                <span style={{ fontFamily: 'Poppins, sans-serif'}}>{index + 1}</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell flexBasis={"60%"} className='text-150'>
                                                                                <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>{data.QUOTATION_NAME}</span><br />
                                                                                <span className='text-150'>• {data.QUOTATION_CODE}</span><br />
                                                                                <span className='text-150'>• {data.QUOTATION_TYPE} Reqeust Quotation</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell flexBasis={"20%"} className='text-150'>
                                                                                <span style={{ fontFamily: 'Poppins, sans-serif'}}>{data.QDATE}</span>
                                                                            </Table.TextCell>
                                                                            <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                                <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                                    <Link>
                                                                                        <DocumentOpenIcon size={16} className='text-primary' />
                                                                                    </Link>
                                                                                </span>
                                                                            </Table.TextCell>
                                                                        </Table.Row>
                                                                        )

                                                                    }
                                                                </Table.Body>
                                                            </Table> 
                                                            <div className='mt-3 d-flex justify-content-end align-items-end'>
                                                                <Pagination page={1} totalPages={1}></Pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            active_menu === "Transaction" && 
                            <>
                                <div className='row mt-4' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-12'>
                                        <div className='card shadow-sm p-4' style={{ transition: '0.5s'}}>
                                            <div>
                                                <h6>Transaction</h6>
                                            </div>
                                            <div>
                                                <div className='hide-on-mobile mt-3'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center'>
                                                            <Button className='mx-1 cbtn' appearance='' backgroundColor='#FFB020' color={color.white}>Pending</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='success'>Success</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='danger'>Failed</Button>
                                                        </div>
                                                        <div>
                                                            <TextInput 
                                                                placeholder='Search'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-12'>
                                                            <Table>
                                                                <Table.Head>
                                                                    <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0}>No.</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"50%"} flexShrink={0} flexGrow={0}>Bill Name</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"15%"} flexShrink={0} flexGrow={0}>Amount (RM)</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"15%"} flexShrink={0} flexGrow={0}>Date Created</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0} className='text-center'>Action</Table.TextHeaderCell>
                                                                </Table.Head>
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>1.</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"50%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>Quotation bill for Sertu Rumah</span><br />
                                                                            <span className='text-150'>• #STU9182391823719731</span><br />
                                                                            <span className='text-150 text-danger'>• Failed</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>RM 250.00</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>12 July 2023, 10:00 AM</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                                <Link>
                                                                                    <DocumentOpenIcon size={16} className='text-primary' />
                                                                                </Link>
                                                                            </span>
                                                                        </Table.TextCell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>2.</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"50%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>Quotation bill for Sertu Hotel</span><br />
                                                                            <span className='text-150'>• #STU9182391823718880</span><br />
                                                                            <span className='text-150 text-success'>• Success</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>RM 999.00</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>12 July 2023, 10:00 AM</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                                <Link>
                                                                                    <DocumentOpenIcon size={16} className='text-primary' />
                                                                                </Link>
                                                                            </span>
                                                                        </Table.TextCell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>1.</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"50%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>Quotation bill for Sertu Rumah</span><br />
                                                                            <span className='text-150'>• #STU9182391823719731</span><br />
                                                                            <span className='text-150 text-danger'>• Failed</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>RM 250.00</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>12 July 2023, 10:00 AM</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                                <Link>
                                                                                    <DocumentOpenIcon size={16} className='text-primary' />
                                                                                </Link>
                                                                            </span>
                                                                        </Table.TextCell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>2.</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"50%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>Quotation bill for Sertu Hotel</span><br />
                                                                            <span className='text-150'>• #STU9182391823718880</span><br />
                                                                            <span className='text-150 text-success'>• Success</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>RM 999.00</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"15%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>12 July 2023, 10:00 AM</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                                <Link>
                                                                                    <DocumentOpenIcon size={16} className='text-primary' />
                                                                                </Link>
                                                                            </span>
                                                                        </Table.TextCell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>  
                                                            <div className='mt-3 d-flex justify-content-end align-items-end'>
                                                                <Pagination page={1} totalPages={1}></Pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            active_menu === "Sertu Service" && 
                            <>
                                <div className='row mt-4' style={{ transition: '0.5s'}}>
                                    <div className='col-12 col-sm-12 col-md-12'>
                                        <div className='card shadow-sm p-4' style={{ transition: '0.5s'}}>
                                            <div>
                                                <h6>Sertu Service</h6>
                                            </div>
                                            <div>
                                                <div className='hide-on-mobile mt-3'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='d-flex align-items-center'>
                                                            <Button className='mx-1 cbtn' appearance='' backgroundColor='#FFB020' color={color.white}>In-Progress</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='success'>Approved</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='danger'>Cancelled</Button>
                                                            <Button className='mx-1 cbtn' appearance='primary' intent='info'>Complete</Button>
                                                        </div>
                                                        <div>
                                                            <TextInput 
                                                                placeholder='Search'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-12'>
                                                            <Table>
                                                                <Table.Head>
                                                                    <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0}>No.</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"60%"} flexShrink={0} flexGrow={0}>Quotation</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"20%"} flexShrink={0} flexGrow={0}>Date Created</Table.TextHeaderCell>
                                                                    <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0} className='text-center'>Action</Table.TextHeaderCell>
                                                                </Table.Head>
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>2.</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"60%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>#123-0827 Quotation for Sertu IKEA Kitchen</span><br />
                                                                            <span className='text-150 text-warning'>• In-Progress</span><br />
                                                                            <span className='text-150'>• Open Quotation</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"20%"} className='text-150'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>23 June 2023, 12:40 PM</span>
                                                                        </Table.TextCell>
                                                                        <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                            <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                                <Link>
                                                                                    <DocumentOpenIcon size={16} className='text-primary' />
                                                                                </Link>
                                                                            </span>
                                                                        </Table.TextCell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>  
                                                            <div className='mt-3 d-flex justify-content-end align-items-end'>
                                                                <Pagination page={1} totalPages={1}></Pagination>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

 
                    </div>
                </section>

            </div>
            <Footer bg='transparent' />
        </div>
    );
}

export default Setting;

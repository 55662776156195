import React, { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';
import GlobalLoading from '../components/GlobalLoading';

const EmptyLayout = ({ children }) => {  
    return (
        <div>
            {/* Outlet is wrapped inside a div with flex: 1 to occupy remaining height */}
            <Suspense fallback={<GlobalLoading />}> 
                <Outlet />
            </Suspense>
        </div>
    );
  };

export default EmptyLayout;

import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, Checkbox, Dialog, FileCard, FileUploader, Heading, Menu, MimeType, Pane, Select, Spinner, TextInput, Textarea, TextareaField, toaster } from 'evergreen-ui';
import { API, GENERATE_CODE, IMAGE_UPLOADER } from '../utils/helper';
import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';
import { GET_DATA } from '../utils/decrypter';
import moment from 'moment';

const CustomQuotation = () => {

    const params    = useParams()
    const state     = useLocation()
    const navigate  = useNavigate()

    const [ACCOUNT_USERNAME , SET_ACCOUNT_USERNAME]     = useState(GET_DATA().ACCOUNT_USERNAME ? GET_DATA().ACCOUNT_USERNAME : "")
    const [ACCOUNT_NAME , SET_ACCOUNT_NAME]             = useState(GET_DATA().ACCOUNT_NAME ? GET_DATA().ACCOUNT_NAME : "")
    const [ACCOUNT_SERCRET_KEY, SET_ACCOUNT_SECRET_KEY] = useState(GET_DATA().ACCOUNT_SERCRET_KEY ? GET_DATA().ACCOUNT_SERCRET_KEY : "")
    const [ACCOUNT_TYPE , SET_ACCOUNT_TYPE]             = useState(GET_DATA().ACCOUNT_TYPE ? GET_DATA().ACCOUNT_TYPE : "")
    const [ACCOUNT_EMAIL , SET_ACCOUNT_EMAIL]           = useState(GET_DATA().ACCOUNT_EMAIL ? GET_DATA().ACCOUNT_EMAIL : "")
    const [ACCOUNT_PHONE , SET_ACCOUNT_PHONE]           = useState(GET_DATA().ACCOUNT_PHONE ? GET_DATA().ACCOUNT_PHONE : "")
    const [ACCOUNT_COUNTRY , SET_ACCOUNT_COUNTRY]       = useState(GET_DATA().ACCOUNT_COUNTRY ? GET_DATA().ACCOUNT_COUNTRY : "")
    const [ACCOUNT_STATE , SET_ACCOUNT_STATE]           = useState(GET_DATA().ACCOUNT_STATE ? GET_DATA().ACCOUNT_STATE : "")
    const [ACCOUNT_ADDRESS , SET_ACCOUNT_ADDRESS]       = useState(GET_DATA().ACCOUNT_ADDRESS ? GET_DATA().ACCOUNT_ADDRESS : "")
    const [ACCOUNT_IMAGE, SET_ACCOUNT_IMAGE]            = useState(GET_DATA().ACCOUNT_IMAGE ? GET_DATA().ACCOUNT_IMAGE : "")

    const [files, setFiles]                     = React.useState([])
    const [fileRejections, setFileRejections]   = React.useState([])

    const [select_address, set_select_address]                              = useState("")
    const [select_service, set_select_service]                              = useState("")
    const [select_user_tpe, set_select_user_type]                           = useState("")
    const [select_service_categories, set_select_service_categories]        = useState("")
    const [site_visit, set_site_visit]                                      = useState(false) 

    const [loading_api, set_loading_api]                                    = useState(true)
    const [dropdown_service, set_dropdows_service]                          = useState([])
    const [dropdown_user_categories, set_dropdown_user_categories]          = useState([])
    const [dropdown_service_categories, set_dropdown_service_categories]    = useState([])

    const [FormFormat, setFormFormat]                                       = useState([])

    const [select_date, set_select_date] = useState("")
    const [select_time, set_select_time] = useState("")

    const [dynamic_form, set_dynamic_form] = useState([{DATA_LABEL: "",DATA_NAME: "",DATA_OPTION: null,DATA_REQUIRED: null, DATA_VALUE: null}])

    const handleChange = React.useCallback((files) => {
        console.log(files)
        setFiles([files[0]])
    }, [])

    const handleRejected    = React.useCallback((fileRejections) => setFileRejections([fileRejections[0]]), [])
    const handleRemove      = React.useCallback(() => {
        setFiles([])
        setFileRejections([])
    }, [])

    useEffect(() => {
        
    }, [])

    useMemo(async () => {
        let api = await API("/dashboard/content", {}, "GET", true)
        if(api.status_code === 200) {
            set_dropdows_service(api.data.SERVICE)
            set_dropdown_service_categories(api.data.SERVICE_CATEGORY)
            set_loading_api(false)
        } else {
            set_loading_api(false)
        }
    }, [])

    useMemo(async () => {
        let api = await API("/dashboard/user-categories", {}, "GET", true)
        if(api.status_code === 200) {
            set_dropdown_user_categories(api.data)
            set_loading_api(false)
        } else {
            set_loading_api(false)
        }
    }, [])

    useMemo(async () => {
        let api = await API("/form/format", {}, "GET", true)
        if(api.status_code === 200) {
            setFormFormat(api.data)
            set_loading_api(false)
        } else {
            set_loading_api(false)
        }
    }, [])

    
    const handleFormChange = (index, value, name) => {
        // console.log("INDEX : ", index)
        // console.log("EVENT : ", value)
        // console.log("NAME : ", name.replace(/\s+/g, "_"))
        let data                    = [...dynamic_form];
        data[index]["DATA_VALUE"]   = value;
        set_dynamic_form(data);
    }

    const onChangeCategories = (value) => {
        let filterArray = FormFormat.filter(a => a.DATA_LABEL === value)
        let new_array_categories = []
        for (let index = 0; index < filterArray.length; index++) {
            new_array_categories.push({
                DATA_LABEL: filterArray[index]["DATA_LABEL"],
                DATA_NAME: filterArray[index]["DATA_NAME"],
                DATA_OPTION: filterArray[index]["DATA_OPTION"],
                DATA_REQUIRED: filterArray[index]["DATA_REQUIRED"],
                DATA_TYPE: filterArray[index]["DATA_TYPE"],
                DATA_VALUE: null
            }) 
        }
        set_dynamic_form(new_array_categories)
    }

    const [isShown, setIsShown] = useState(false)

    const __SubmitButton = async () => {

        if(!select_service) 
        {
            toaster.danger("Service Not Found", { description: 'Please select your desired service.'})
        }
        else if(!select_user_tpe) 
        {
            toaster.danger("User Category Not Found", { description: 'Please select your desired user category.'})
        }
        else if(!select_service_categories) 
        {
            toaster.danger("Service Category Not Found", { description: 'Please select your desired service category.'})
        }
        else if(!select_date) 
        {
            toaster.danger("Incomplete Date Request", { description: 'Please select your date request.'})
        }
        else if(!select_time) 
        {
            toaster.danger("Incomplete Time Request", { description: 'Please select your time request.'})
        }
        else if(!select_service_categories) 
        {
            toaster.danger("Incomplete Time Reqeust", { description: 'Please select your time request.'})
        }
        else if(!ACCOUNT_NAME) 
        {
            toaster.danger("Incomlete Name", { description: 'Please complete your name.'})
        }
        else if(!ACCOUNT_EMAIL) 
        {
            toaster.danger("Incomplete Email", { description: 'Please complete your email.'})
        }
        else if(!ACCOUNT_PHONE) 
        {
            toaster.danger("Incomplete Phone", { description: 'Please complete your phone.'})
        }
        else {

            setIsShown(true)    
            let upload_image = await __UploadImage();

            if(upload_image.status) {
                let image_url = upload_image.url

                let json = {
                    RF_NAME: `Quotation For ${select_service} ${GENERATE_CODE()}`,
                    SERVICE: select_service,
                    USER_CATEGORY: select_user_tpe,
                    SERVICE_CATEGORY: select_service_categories,
                    RF_DESCRIPTION: `Requesting ${select_service} Quotation For ${select_user_tpe} ${select_service_categories}`,
                    RF_DATE: select_date,
                    RF_TIME: select_time,
                    RF_LOCATION: select_address,
                    OWNER_NAME: ACCOUNT_NAME,
                    OWNER_EMAIL: ACCOUNT_EMAIL,
                    OWNER_PHONE: ACCOUNT_PHONE,
                    RF_ITEMS: dynamic_form,
                    RF_IMAGE: image_url
                }

                let api = await API("form/create-form", json, "POST", true)
                console.log("Log Function Request Quotation Service Form : ", api)

                if(api.status_code === 200) 
                {
                    toaster.success("Congratulation! Your request quotation created successfully.")
                    window.location.href = "my-quotation"
                    setIsShown(false)  
                } 
                else 
                {
                    toaster.danger("Sorry! Your request quotation cannot be processed at the moment.")
                    setIsShown(false)  
                }
            } 
            else 
            {
                toaster.danger("Unable to upload your image. Complete form below or resize your image.")
                setIsShown(false)  
            }

        }

    }

    const __UploadImage = async () => {
        if(!files || files.length < 1) {
            toaster.danger("Please upload at least 1 image!")
            return {
                status: false,
                url: ""
            }
        } else {
            let upload = await IMAGE_UPLOADER(files, files.file)
            console.log("RESULT UPLOAD IMAGE : ", upload)
            return upload;
        }
    }

    return (
        <div>
            {/* <BasicTopHeader title={"Quotation"} /> */}
            <TopHeader />

            <Pane>
                <Dialog
                    isShown={isShown}
                    onCloseComplete={() => setIsShown(false)}
                    hasHeader={false}
                    hasFooter={false}
                    preventBodyScrolling={isShown}
                    shouldCloseOnOverlayClick={false}
                >
                    <Pane display="flex" flexDirection="column" alignItems="center" justifyContent="center" height={400}>
                        <Spinner />
                        <Heading className='mt-3'>Please wait while we are processing your quotation form.</Heading>
                    </Pane>
                </Dialog>
            </Pane>

            <section style={{ marginTop: 100 }}>

                <div className='show-on-mobile'>
                    <section className='mt-5'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='p-4 floating-search' style={{ width: '990px', marginTop: 0}}>
                                        <Link to={-1}>
                                            <ArrowLeftIcon size={24} color='black' />
                                        </Link>
                                        <div className='small-line-height mb-2 mb-sm-0 mt-3'>
                                            <h5>Quotation Form</h5>
                                            <span className='text-secondary text-200'>Please complete your quotation form below.</span>
                                        </div>
                                        <div className='row mt-5'>
                                            <div className='col-12'>
                                                <div className='form-group'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Name</h6>
                                                    <TextInput 
                                                        defaultValue={ACCOUNT_NAME} 
                                                        onKeyUp={e => SET_ACCOUNT_NAME(e.target.value)}
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='John Doe' 
                                                    />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Email</h6>
                                                    <TextInput 
                                                        defaultValue={ACCOUNT_EMAIL} 
                                                        onKeyUp={e => SET_ACCOUNT_EMAIL(e.target.value)}
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='johndoe@gmail.com' 
                                                    />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Phone</h6>
                                                    <TextInput 
                                                        defaultValue={ACCOUNT_PHONE} 
                                                        onKeyUp={e => SET_ACCOUNT_PHONE(e.target.value)}
                                                        type='number' 
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='0123456789' 
                                                    />
                                                </div>
                                                {
                                                    FormFormat.length &&
                                                    <>
                                                    <div className='form-group mt-3'>
                                                        <h6 className='text-200'><span className='text-danger'>* </span>Service</h6>
                                                        <Select width="100%" defaultValue={select_service} onChange={e => set_select_service(e.target.value)}>
                                                            <option value="">-- Select Service--</option>
                                                            {
                                                                dropdown_service.map((item, index) => 
                                                                    <option key={index} value={item.SERVICE_NAME}>{item.SERVICE_NAME}</option>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div className='form-group mt-3'>
                                                        <h6 className='text-200'><span className='text-danger'>* </span>User Category</h6>
                                                        <Select width="100%" defaultValue={select_service_categories} onChange={e => set_select_user_type(e.target.value)}>
                                                        <option value={""}>-- Select User Type--</option>
                                                        {
                                                            dropdown_user_categories.map((item, index) => 
                                                                <option key={index} value={item.title}>{item.title}</option>
                                                            )
                                                        }
                                                        </Select>
                                                    </div>
                                                    <div className='form-group mt-3'>
                                                        <h6 className='text-200'><span className='text-danger'>* </span>Service Categories</h6>
                                                        <Select width="100%" 
                                                        disabled={select_user_tpe ? false : true}
                                                        onChange={e => {
                                                            onChangeCategories(e.target.value);
                                                            set_select_service_categories(e.target.value)
                                                        }}>
                                                            <option value={""}>{ select_user_tpe ? "-- Select Categories --" : "-- Please Select User Type --"}</option>
                                                            {
                                                                dropdown_user_categories.map((item, index) => item.title === select_user_tpe &&
                                                                    item.value.map((items, index) =>
                                                                        <option key={index} value={items}>{items}</option>
                                                                    )
                                                                    
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    </>
                                                }
                                                <div className='form-group mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Address</h6>
                                                    <Textarea 
                                                        defaultValue={ACCOUNT_ADDRESS} 
                                                        onKeyUp={e => SET_ACCOUNT_ADDRESS(e.target.value)}
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='808 East Meadow Drive Eastpointe, MI 48021' 
                                                    />
                                                </div>
                                                <div className='mt-3'>
                                                {
                                                    dynamic_form.length > 0 && dynamic_form.map((data, index) => data.DATA_LABEL !== "" && data.DATA_LABEL ===  select_service_categories &&
                                                    <div key={index}>
                                                        <div key={index} className='form-group mt-3'>
                                                            <h6 className='text-200'><span className='text-danger'>* </span>{data.DATA_NAME}</h6>
                                                            {
                                                                data.DATA_TYPE === "Array" && (
                                                                    <Select className='w-100' name={data.DATA_NAME} onChange={event => handleFormChange(index, event.target.value, data.DATA_NAME)}>
                                                                        <option value={""}>-- Select {data.DATA_NAME} --</option>
                                                                        {
                                                                            data.DATA_OPTION.map((data, index) =>
                                                                                <option key={index} value={data}>{data}</option>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                )
                                                            }
                                                            {
                                                                data.DATA_TYPE !== "Array" && data.DATA_NAME !== "Remarks"  && (
                                                                    <TextInput 
                                                                        name={data.DATA_NAME}
                                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                                        className='w-100' 
                                                                        placeholder='Enter here...' 
                                                                        onKeyUp={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                data.DATA_NAME === "Remarks"  && (
                                                                    <Textarea 
                                                                        name={data.DATA_NAME}
                                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                                        className='w-100' 
                                                                        placeholder='Enter here...' 
                                                                        onKeyUp={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                                </div>
                                                <div className='mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Image / Video</h6>
                                                    <FileUploader
                                                        maxSizeInBytes={50 * 1024 ** 2}
                                                        maxFiles={1}
                                                        onChange={handleChange}
                                                        onRejected={handleRejected}
                                                        renderFile={(file) => {
                                                            const { name, size, type } = file
                                                            const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                                            const { message } = fileRejection || {}
                                                            return (
                                                                <FileCard
                                                                key={name}
                                                                isInvalid={fileRejection != null}
                                                                name={name}
                                                                onRemove={handleRemove}
                                                                sizeInBytes={size}
                                                                type={type}
                                                                validationMessage={message}
                                                                />
                                                            )
                                                        }}
                                                        values={files}
                                                    />
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <footer className='bg-white w-100' 
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            padding: '16px',
                            zIndex: 5
                        }}>
                            <div className='w-100 d-flex justify-content-between align-items-center m-auto' style={{ maxWidth: '787px'}}>
                                <div >
                                    <span className='text-200 text-secondary'>Request For:</span>
                                    <h6 className='text-200'>{select_service ? select_service : '-- None --'}</h6>
                                </div>
                                <Link onClick={__SubmitButton} className='btn bg-custom-primary text-200'>
                                    <span className='text-white text-200'>Submit Request</span>
                                </Link>
                            </div>
                        </footer>
                </div>

                <div className='hide-on-mobile'>

                    {/* <div className='row mt-5 div-container'>
                        <div className='col-sm-12'>
                            <div className='mt-5 col-md-12'>
                                <Link to={-1}>
                                    <ArrowLeftIcon size={24} color='black' />
                                </Link>
                            </div>
                        </div>
                    </div> */}

                    <div className='row my-2'>
                        <div className='col-sm-12'>
                            <div className='mt-5 col-md-12 text-center'>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">Custom Quotation</p>
                                <span className='text-200 text-secondary'>We are responsible in providing the best range of cleaning services, by providing the best quality.</span>
                            </div>
                        </div>
                    </div>

                    <form className='form-group' style={{ marginTop: 100}}>

                        <div className='row div-container'>
                            <div className='col-12 col-sm-4'>
                                <strong>Personal Information</strong><br />
                                <small className='text-200 text-secondary small-line-height'>Your personal information helps us to find out the best for you.</small>
                            </div>
                            <div className='col-12 col-sm-8'>
                                <div className='form-group'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Name</strong>
                                    <TextInput 
                                        defaultValue={ACCOUNT_NAME} 
                                        onKeyUp={e => SET_ACCOUNT_NAME(e.target.value)}
                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                        className='w-100 mt-1' 
                                        placeholder='John Doe' 
                                        size='large'
                                    />
                                </div>
                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Email</strong>
                                    <TextInput 
                                        defaultValue={ACCOUNT_EMAIL} 
                                        onKeyUp={e => SET_ACCOUNT_EMAIL(e.target.value)}
                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                        className='w-100 mt-1' 
                                        placeholder='johndoe@gmail.com' 
                                        size='large'
                                    />
                                </div>
                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Phone</strong>
                                    <TextInput 
                                        defaultValue={ACCOUNT_PHONE} 
                                        onKeyUp={e => SET_ACCOUNT_PHONE(e.target.value)}
                                        type='number' 
                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                        className='w-100 mt-1' 
                                        placeholder='0123456789' 
                                        size='large'
                                    />
                                </div>
                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Address</strong>
                                    <Textarea 
                                        defaultValue={ACCOUNT_ADDRESS} 
                                        onKeyUp={e => SET_ACCOUNT_ADDRESS(e.target.value)}
                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                        className='w-100 mt-1' 
                                        placeholder='808 East Meadow Drive Eastpointe, MI 48021' 
                                        size={"large"}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row div-container my-5'>
                            <div className='col-12'>
                                <Menu.Divider />
                            </div>
                        </div>

                        <div className='row div-container'>
                            <div className='col-12 col-sm-4'>
                                <strong>Quotation Request Information</strong><br />
                                <small className='text-200 text-secondary small-line-height'>
                                    Configure your needs by completing the next form.
                                </small>
                            </div>
                            <div className='col-12 col-sm-8'>
                            {
                                FormFormat.length &&
                                <>
                                <div className='row'>
                                    <div className='col-12 col-sm-6'>
                                        <div className='form-group'>
                                            <strong className='text-300 mb-1'><span className='text-danger'>* </span>Date</strong>
                                            <TextInput 
                                                defaultValue={select_date} 
                                                onChange={e => set_select_date(e.target.value)}
                                                type='date' 
                                                style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                className='w-100 mt-1'  
                                                size='large'
                                                min={moment(new Date()).add(3, 'days').format('YYYY-MM-DD')}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-6'>
                                        <div className='form-group'>
                                            <strong className='text-300 mb-1'><span className='text-danger'>* </span>Time</strong>
                                            <TextInput 
                                                defaultValue={select_time} 
                                                onChange={e => set_select_time(e.target.value)}
                                                type='time' 
                                                style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                className='w-100 mt-1'  
                                                size='large'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Request Location</strong>
                                    <TextareaField 
                                        defaultValue={select_address} 
                                        onChange={e => set_select_address(e.target.value)}
                                        type='time' 
                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                        className='w-100 mt-1'  
                                        size='large'
                                        placeholder='Enter your location address: Lot 718, Taman Tasik Titiwangsa, 57000 Kuala Lumpur'
                                    />
                                </div>
                                
                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Service</strong>
                                    <Select 
                                        className='mt-1'
                                        width="100%" 
                                        defaultValue={select_service} 
                                        onChange={e => set_select_service(e.target.value)}
                                        size='large'
                                    >
                                        <option value="">-- Select Service--</option>
                                        {
                                            dropdown_service.map((item, index) => 
                                                <option key={index} value={item.SERVICE_NAME}>{item.SERVICE_NAME}</option>
                                            )
                                        }
                                    </Select>
                                </div>
                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>User Category</strong>
                                    <Select 
                                        className='mt-1'
                                        width="100%" 
                                        defaultValue={select_service_categories} 
                                        onChange={e => set_select_user_type(e.target.value)}
                                        size='large'
                                    >
                                    <option value={""}>-- Select User Type--</option>
                                    {
                                        dropdown_user_categories.map((item, index) => 
                                            <option key={index} value={item.title}>{item.title}</option>
                                        )
                                    }
                                    </Select>
                                </div>
                                <div className='form-group mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Service Categories</strong>
                                    <Select 
                                        className='mt-1'
                                        width="100%" 
                                        disabled={select_user_tpe ? false : true}
                                        onChange={e => {
                                            onChangeCategories(e.target.value);
                                            set_select_service_categories(e.target.value)
                                        }}
                                        size='large'
                                    >
                                        <option value={""}>{ select_user_tpe ? "-- Select Categories --" : "-- Please Select User Type --"}</option>
                                        {
                                            dropdown_user_categories.map((item, index) => item.title === select_user_tpe &&
                                                item.value.map((items, index) =>
                                                    <option key={index} value={items}>{items}</option>
                                                )
                                                
                                            )
                                        }
                                    </Select>
                                </div>
                                </>
                            }
                            {
                                dynamic_form.length > 0 && dynamic_form.map((data, index) => data.DATA_LABEL !== "" && data.DATA_LABEL ===  select_service_categories &&
                                <div key={index}>
                                    <div key={index} className='form-group mt-3'>
                                        <h6 className='text-300'><span className='text-danger'>* </span>{data.DATA_NAME}</h6>
                                        {
                                            data.DATA_TYPE === "Array" && (
                                                <Select 
                                                    className='w-100' 
                                                    name={data.DATA_NAME} 
                                                    onChange={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                    size='large'    
                                                >
                                                    <option value={""}>-- Select {data.DATA_NAME} --</option>
                                                    {
                                                        data.DATA_OPTION.map((data, index) =>
                                                            <option key={index} value={data}>{data}</option>
                                                        )
                                                    }
                                                </Select>
                                            )
                                        }
                                        {
                                            data.DATA_TYPE !== "Array" && data.DATA_NAME !== "Remarks"  && (
                                                <TextInput 
                                                    name={data.DATA_NAME}
                                                    style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                    size='large'
                                                    className='w-100' 
                                                    placeholder='Enter here...' 
                                                    onKeyUp={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                />
                                            )
                                        }
                                        {
                                            data.DATA_NAME === "Remarks"  && (
                                                <Textarea 
                                                    size='large'    
                                                    name={data.DATA_NAME}
                                                    style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                    className='w-100' 
                                                    placeholder='Enter here...' 
                                                    onKeyUp={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                                )
                            }
                            {
                                select_user_tpe &&
                                <>
                                    <div className='mt-3'>
                                        <div style={{ display: 'flex', alignItems: 'center'}}>
                                            <Checkbox
                                                style={{
                                                    fontFamily: 'Poppins, sans-serif'
                                                }}
                                                fontFamily="Poppins, sans-serif"
                                                checked={site_visit}
                                                onChange={e => set_site_visit(e.target.checked)}
                                            />
                                            <div className="px-2 small-line-height">
                                                <span className="text-200 text-secondary">
                                                    Request for site visit?
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            </div>

                        </div>

                        <div className='row div-container my-5'>
                            <div className='col-12'>
                                <Menu.Divider />
                            </div>
                        </div>

                        <div className='row div-container'>
                            <div className='col-12 col-sm-4'>
                                <strong>Request Image</strong><br />
                                <small className='text-200 text-secondary small-line-height'>
                                    We need at least one image to figure out your request area.
                                </small>
                            </div>
                            <div className='col-12 col-sm-8'>
                                <div className='mt-3'>
                                    <strong className='text-300'><span className='text-danger'>* </span>Image / Video</strong>
                                    <FileUploader
                                        acceptedMimeTypes={[MimeType.jpeg, MimeType.png, MimeType.jpeg, MimeType.svg, MimeType.svg]}
                                        className=''
                                        maxSizeInBytes={50 * 1024 ** 2}
                                        maxFiles={1}
                                        onChange={handleChange}
                                        onRejected={handleRejected}
                                        renderFile={(file) => {
                                        const { name, size, type } = file
                                        const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                        const { message } = fileRejection || {}
                                        return (
                                            <FileCard
                                            key={name}
                                            isInvalid={fileRejection != null}
                                            name={name}
                                            onRemove={handleRemove}
                                            sizeInBytes={size}
                                            type={type}
                                            validationMessage={message}
                                            />
                                        )
                                        }}
                                        values={files}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row div-container mt-5 mb-3'>
                            <div className='col-12'>
                                <Menu.Divider />
                            </div>
                        </div>

                        <div className='row div-container'>
                            <div className='col-12 d-flex justify-content-end'>
                                {/* <Button onClick={__SubmitButton} type='button' size='large' appearance='primary' intent='success'>Submit Request</Button> */}
                                <button
                                    onClick={__SubmitButton}
                                    type="button"
                                    className="flex items-center justify-center rounded-md border border-transparent bg-emerald-600 py-2 px-4 text-sm text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                {/* <div 
                    className='hide-on-mobile jumbotron' 
                    style={{ 
                        backgroundImage: `url('https://uploads-ssl.webflow.com/5fbde3dc76aea24cb3b0ae52/6017bb2859b475c6f95ef05f_home-cleaning-hero.jpg')`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        imageResolution: 'from-image'
                    }}
                >
                    <div className='row p-3' 
                    style={{
                        height: '100%',
                        background: 'rgb(255,255,255)',
                        background: 'linear-gradient(0deg, transparent 0%, rgba(0,0,0,5) 100%)',
                    }}>
                        <Link to={-1} replace={true} className='mt-5 div-container'>
                            <ArrowLeftIcon size={24} color='white' />
                        </Link>

                        <div className='mt-5 col-md-12 text-center'>
                            <h3 className='text-white'>Get a Quotation</h3>
                            <span className='text-200 text-white'>We are responsible in providing the best range of cleaning services, by providing the best quality.</span>
                        </div>

                        <section className='px-3 mt-5'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='card p-4 mx-auto mb-5' style={{ maxWidth: '990px'}}>
                                        <div className='small-line-height mb-2 mb-sm-0'>
                                            <h5>Quotation Form</h5>
                                            <span className='text-secondary text-200'>Please complete your quotation form below.</span>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-12 col-sm-6'>
                                                <div className='form-group'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Name</h6>
                                                    <TextInput 
                                                        defaultValue={ACCOUNT_NAME} 
                                                        onKeyUp={e => SET_ACCOUNT_NAME(e.target.value)}
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='John Doe' 
                                                    />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Email</h6>
                                                    <TextInput 
                                                        defaultValue={ACCOUNT_EMAIL} 
                                                        onKeyUp={e => SET_ACCOUNT_EMAIL(e.target.value)}
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='johndoe@gmail.com' 
                                                    />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Phone</h6>
                                                    <TextInput 
                                                        defaultValue={ACCOUNT_PHONE} 
                                                        onKeyUp={e => SET_ACCOUNT_PHONE(e.target.value)}
                                                        type='number' 
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='0123456789' 
                                                    />
                                                </div>
                                                {
                                                    FormFormat.length &&
                                                    <>
                                                    <div className='form-group mt-3'>
                                                        <h6 className='text-200'><span className='text-danger'>* </span>Service</h6>
                                                        <Select width="100%" defaultValue={select_service} onChange={e => set_select_service(e.target.value)}>
                                                            <option value="">-- Select Service--</option>
                                                            {
                                                                dropdown_service.map((item, index) => 
                                                                    <option key={index} value={item.SERVICE_NAME}>{item.SERVICE_NAME}</option>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    <div className='form-group mt-3'>
                                                        <h6 className='text-200'><span className='text-danger'>* </span>User Category</h6>
                                                        <Select width="100%" defaultValue={select_service_categories} onChange={e => set_select_user_type(e.target.value)}>
                                                        <option value={""}>-- Select User Type--</option>
                                                        {
                                                            dropdown_user_categories.map((item, index) => 
                                                                <option key={index} value={item.title}>{item.title}</option>
                                                            )
                                                        }
                                                        </Select>
                                                    </div>
                                                    <div className='form-group mt-3'>
                                                        <h6 className='text-200'><span className='text-danger'>* </span>Service Categories</h6>
                                                        <Select width="100%" 
                                                        disabled={select_user_tpe ? false : true}
                                                        onChange={e => {
                                                            onChangeCategories(e.target.value);
                                                            set_select_service_categories(e.target.value)
                                                        }}>
                                                            <option value={""}>{ select_user_tpe ? "-- Select Categories --" : "-- Please Select User Type --"}</option>
                                                            {
                                                                dropdown_user_categories.map((item, index) => item.title === select_user_tpe &&
                                                                    item.value.map((items, index) =>
                                                                        <option key={index} value={items}>{items}</option>
                                                                    )
                                                                    
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                    </>
                                                }
                                                
                                                <div className='mt-3'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Image / Video</h6>
                                                    <FileUploader
                                                        maxSizeInBytes={50 * 1024 ** 2}
                                                        maxFiles={1}
                                                        onChange={handleChange}
                                                        onRejected={handleRejected}
                                                        renderFile={(file) => {
                                                        const { name, size, type } = file
                                                        const fileRejection = fileRejections.find((fileRejection) => fileRejection.file === file)
                                                        const { message } = fileRejection || {}
                                                        return (
                                                            <FileCard
                                                            key={name}
                                                            isInvalid={fileRejection != null}
                                                            name={name}
                                                            onRemove={handleRemove}
                                                            sizeInBytes={size}
                                                            type={type}
                                                            validationMessage={message}
                                                            />
                                                        )
                                                        }}
                                                        values={files}
                                                    />
                                                </div>
                                                <Button appearance='primary' intent='success' onClick={__SubmitButton}>Submit Form</Button>
                                            </div>
                                            <div className='col-12 col-sm-6'>

                                                <div className='form-group'>
                                                    <h6 className='text-200'><span className='text-danger'>* </span>Address</h6>
                                                    <Textarea 
                                                        defaultValue={ACCOUNT_ADDRESS} 
                                                        onKeyUp={e => SET_ACCOUNT_ADDRESS(e.target.value)}
                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                        className='w-100' 
                                                        placeholder='808 East Meadow Drive Eastpointe, MI 48021' 
                                                    />
                                                </div>

                                                {
                                                    dynamic_form.length > 0 && dynamic_form.map((data, index) => data.DATA_LABEL !== "" && data.DATA_LABEL ===  select_service_categories &&
                                                    <div key={index}>
                                                        <div key={index} className='form-group mt-3'>
                                                            <h6 className='text-200'><span className='text-danger'>* </span>{data.DATA_NAME}</h6>
                                                            {
                                                                data.DATA_TYPE === "Array" && (
                                                                    <Select className='w-100' name={data.DATA_NAME} onChange={event => handleFormChange(index, event.target.value, data.DATA_NAME)}>
                                                                        <option value={""}>-- Select {data.DATA_NAME} --</option>
                                                                        {
                                                                            data.DATA_OPTION.map((data, index) =>
                                                                                <option key={index} value={data}>{data}</option>
                                                                            )
                                                                        }
                                                                    </Select>
                                                                )
                                                            }
                                                            {
                                                                data.DATA_TYPE !== "Array" && data.DATA_NAME !== "Remarks"  && (
                                                                    <TextInput 
                                                                        name={data.DATA_NAME}
                                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                                        className='w-100' 
                                                                        placeholder='Enter here...' 
                                                                        onKeyUp={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                                    />
                                                                )
                                                            }
                                                            {
                                                                data.DATA_NAME === "Remarks"  && (
                                                                    <Textarea 
                                                                        name={data.DATA_NAME}
                                                                        style={{ fontFamily: 'Poppins, sans-serif'}} 
                                                                        className='w-100' 
                                                                        placeholder='Enter here...' 
                                                                        onKeyUp={event => handleFormChange(index, event.target.value, data.DATA_NAME)}
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div> */}
                <Footer bg='transparent' />
            </section>
            {/* <Footer bg='transparent' /> */}
        </div>
    );
}

export default CustomQuotation;

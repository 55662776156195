import React, { lazy, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';

import AuthLayout from "./layouts/AuthLayout"
import EmptyLayout from "./layouts/EmptyLayout"
import SendQuotation from './pages/SendQuotation';
import PublicLayout from './layouts/PublicLayout';
import PrivateLayout from './layouts/PrivateLayout';
import MyQuotation from './pages/MyQuotation';
import Setting from './pages/Setting';
import { CHECK_SESSION } from './utils/decrypter';
import Register from './pages/Register';
import CustomQuotation from './pages/CustomQuotation';
import PDFViewer from './pages/PDFViewer';
import PayQuotation from './pages/PayQuotation';
import Receipt from './pages/Receipt';
import SertuAuthorizationLetter from './pages/SertuAuthorizationLetter';
import SertuCertificate from './pages/SertuCertificate';

const GetStarted 		= lazy(() => import("./pages/GetStarted"));
const Login 			= lazy(() => import("./pages/Login")); 
const Home 			 	= lazy(() => import("./pages/Home"))
const FindOperator 		= lazy(() => import("./pages/FindOperator"))
const SearchOperator	= lazy(() => import("./pages/SearchOperator"))
const Agent 			= lazy(() => import("./pages/Agent"))
const GetQuote 			= lazy(() => import("./pages/GetQuote"))
const SendQuote 		= lazy(() => import("./pages/SendQuotation"))
const QuotationDetail 	= lazy(() => import("./pages/QuotationDetail"))
const InformationCentre = lazy(() => import("./pages/InformationCenter"))

function App() {

	const PageNotFound = () => (
		<div className='d-flex flex-direction-column justify-content-center align-items-center' style={{ height: '100vh'}}> 
			<div className='text-center'>
				<h4>Page is not found.</h4>
				<div className='mt-2'>
					<button type='button' className='btn btn-default bg-custom-primary'>
						<Link to={"/"} className='text-decoration-none text-white'>
							<span>Go to home</span>
						</Link>
					</button>
				</div>
			</div>
		</div>
	)

	useEffect(() => {
		window.addEventListener("storage", () => {
			console.log("CHANGES DETECTED!!!")
			window.localStorage.clear()
			window.location.href = "/"
		})

		window.addEventListener("click", () => {
			
		})
	}, [])

	return (
		<main>
			<Router>
				<Routes>

					<Route element={<PublicLayout />}>
						<Route path='/login' element={<Login />} />
						<Route path='/signup' element={<Register />} />
					</Route>

					<Route element={<AuthLayout />}>
						<Route path='/' index element={<Home />}/>
						<Route path='/information' element={<InformationCentre />} />
						<Route path='/receipt' element={<Receipt />} />
						<Route path="*" element={<Navigate to="/404" />} />
					</Route>

					<Route element={<EmptyLayout />}>
						<Route path='/main' element={<Home />} />
						<Route path='/find-operator' element={<FindOperator />} />
						<Route path='/operator/:name' element={<SearchOperator />} />
						<Route path='/agent/:name' element={<Agent />} />
						<Route path='/get-quote' element={<GetQuote />} />
						<Route path='/custom-quotation' element={<CustomQuotation />} />
						<Route path='/pdf-viewer.pdf' element={<PDFViewer />} />
					</Route>

					<Route element={<PrivateLayout />} >
						<Route path='/quotation' element={<SendQuotation />} />
						<Route path='/my-quotation' element={<MyQuotation />} />
						<Route path='/quotation/:name' element={<QuotationDetail />} />
						<Route path='/bill/:oqformcode' element={<PayQuotation />} />
						<Route path='/setting' element={<Setting />} />
						<Route path='/sertu-authorization-letter' element={<SertuAuthorizationLetter />} />
						<Route path='/sertu/certificate' element={<SertuCertificate />} />
					</Route>

					<Route path='/404' element={<PageNotFound />} />
				</Routes>
			</Router>
		</main>
	);
}

export default App;

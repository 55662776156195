export const API = async (API_NAME = "", BODY = {}, METHOD = "POST", AUTH = false)  => {

    let result      = {
        status_code: null,
        status: "",
        message: "",
        data: {}
    }
    
    let BASE_URL    = process.env.NODE_ENV === "production" ? `https://esertu.com/api/`  : `https://esertu.com/api/`
    let data        = BODY;

    let TOKEN = window.localStorage.getItem("_aT") ? window.localStorage.getItem("_aT") : null

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    if(AUTH && TOKEN) {
        myHeaders.append("Authorization", `Bearer ${TOKEN}`);
        myHeaders.append("token", `${TOKEN}`);
    }
    
    var raw = JSON.stringify(data);

    var requestOptions = {};

    if(METHOD === "GET") {
        requestOptions = {
            method: METHOD,
            headers: myHeaders,
            redirect: 'follow'
        }
    } else {
        requestOptions = {
            method: METHOD,
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        }
    }

    await fetch(BASE_URL + API_NAME, requestOptions)
    .then(response => response.json())
    .then(res => {
        result = {
            status_code: res.status_code,
            status: res.status,
            message: res.message,
            data: res.data
        }
    })
    .catch(error => {
        result = {
            status_code: 500,
            status: 'Internal Server Error',
            message: 'Technical Issue. Please contact your system administrator.',
            data: null
        }
    });

    return result
}


export const IMAGE_UPLOADER = async (file, filename) => {

    const uuid      = require("uuid").v4();

    let result      = null
    var formdata    = new FormData();
    formdata.append("file", file[0]);
    
    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        cors: 'no-cors'
    };
    
    let BASE_URL    = process.env.NODE_ENV === "production" ? `https://e-sertu.com/api/upload`  : `https://e-sertu.com/api/upload`
    await fetch(BASE_URL, requestOptions)
    .then(response => response.json())
    .then(res => {
        if(res.status_code === 200) 
        {
            result = {
                status: true,
                url: res.url
            }
        } else {
            result = {
                status: false,
                url: null
            }
        }
    })
    .catch(error => {
        result = {
            status: false,
            url: null
        }
    });

    return result;
}

export const GENERATE_CODE = () => {
    return '#' + Math.floor(100000000 + Math.random() * 999999999);
};

export const BANK_FPX = [
    {
        CODE: "ABB0233",
        NAME: "Affin Bank"
    },
    {
        CODE: "AGRO01",
        NAME: "AGRONet"
    },
    {
        CODE: "ABMB0212",
        NAME: "Alliance Bank"
    },
    {
        CODE: "AMBB0209",
        NAME: "AmBank"
    },
    {
        CODE: "BIMB0340",
        NAME: "Bank Islam"
    },
    {
        CODE: "BMMB0341",
        NAME: "Bank Muamalat"
    },
    {
        CODE: "BOCM01",
        NAME: "Bank Of China"
    },
    {
        CODE: "BKRM0602",
        NAME: "Bank Rakyat"
    },
    {
        CODE: "BSN0601",
        NAME: "Bank Simpanan Nasional"
    },
    {
        CODE: "BCBB0235",
        NAME: "CIMB Clicks"
    },
    {
        CODE: "HLB0224",
        NAME: "Hong Leong Bank"
    },
    {
        CODE: "HSBC0223",
        NAME: "HSBC Bank"
    },
    {
        CODE: "KFH0346",
        NAME: "KFH"
    },
    {
        CODE: "MBB0228",
        NAME: "Maybank2E"
    },
    {
        CODE: "MB2U0227",
        NAME: "Maybank2U"
    },
    {
        CODE: "OCBC0229",
        NAME: "OCBC Bank"
    },
    {
        CODE: "PBB0233",
        NAME: "Public Bank"
    },
    {
        CODE: "RHB0218",
        NAME: "RHB Bank"
    },
    {
        CODE: "SCB0216",
        NAME: "Standard Chartered"
    },
    {
        CODE: "UOB0226",
        NAME: "UOB Bank"
    }
]
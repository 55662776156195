import { BookIcon, Button, Card, ChevronDownIcon, DocumentIcon, GridViewIcon, Heading, HeatGridIcon, HomeIcon, Icon, LogInIcon, LogOutIcon, Menu, MenuIcon, Pane, Paragraph, Popover, Position, SearchInput, SettingsIcon, SideSheet, Tab, Tablist, UserIcon, toaster } from 'evergreen-ui';
import React, { useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useNavigation } from 'react-router-dom';
import { API } from '../utils/helper';
import { CHECK_SESSION, GET_DATA } from '../utils/decrypter';
import { color } from '../constant';

const TopHeader = () => {

    const routepush = useNavigate()
    const [dropdown_service, set_dropdows_service]                          = useState([])
    const [dropdown_service_categories, set_dropdown_service_categories]    = useState([])
    const [isShown, setIsShown]                                             = React.useState(false)
    const [selectedIndex, setSelectedIndex]                                 = React.useState(0)

    useMemo(async () => {
        let api = await API("/dashboard/content", {}, "GET", true)
            if(api.status_code === 200) {
                set_dropdows_service(api.data.SERVICE)
                set_dropdown_service_categories(api.data.SERVICE_CATEGORY)
            } 
            else 
            {

            }
    }, [])

    const __Logout = () => {
        window.localStorage.clear()
        routepush("/login", { replace: true })
        window.location.reload()
    }

    return (
        <div>

            <React.Fragment>
                <SideSheet
                    isShown={isShown}
                    onCloseComplete={() => setIsShown(false)}
                    width={280}
                    containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                    }}
                >
                    <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white" className='text-center w-100'>
                        <Pane padding={16} borderBottom="muted" className='text-center'>
                            <Link to={"/"} className='d-flex align-items-center justify-content-center text-center'>
                                <img src={require("../assets/Logo/NLOGO.png")} className='mx-1' style={{ width: 50, height: 30 }} />
                            </Link>
                        </Pane>
                    </Pane>
                    <Pane flex="1" overflowY="scroll" background="tint1" padding={12}>
                        <Menu>
                            {
                                CHECK_SESSION() &&
                                <div className='my-3'>
                                    <UserIcon color={color.primary} size={24} style={{ marginTop: 0}} />
                                    <span className='fw-bold px-2 text-200' style={{ fontFamily: 'Poppins, sans-serif'}}>Hi, {GET_DATA().ACCOUNT_NAME}</span>
                                </div>
                            }

                            <Menu.Group>
                                <Heading fontSize={14}>Main</Heading>
                                <Menu.Item className='mb-1 mt-1' icon={HomeIcon}>
                                    <Link to={"/"} className='text-200 text-secondary' style={{ fontFamily: 'Poppins, sans-serif'}}>Home</Link>
                                </Menu.Item>
                                <Menu.Item className='mb-1 mt-1' icon={HeatGridIcon}>
                                    <Link className='text-200 text-secondary' style={{ fontFamily: 'Poppins, sans-serif'}}>Service</Link>
                                </Menu.Item>
                                <Menu.Item className='' icon={GridViewIcon}>
                                    <Link className='text-200 text-secondary' style={{ fontFamily: 'Poppins, sans-serif'}}>Categories</Link>
                                </Menu.Item>
                            </Menu.Group>

                            {
                                CHECK_SESSION() &&
                                <Menu.Group>
                                    <Heading fontSize={14}>Account</Heading>
                                    <Menu.Item className='mb-1 mt-1' icon={DocumentIcon}>
                                        <Link to={"/my-quotation"} className='text-200 text-secondary' style={{ fontFamily: 'Poppins, sans-serif'}}>My Quotation (0)</Link>
                                    </Menu.Item>
                                    <Menu.Item className='mb-1 mt-1' icon={BookIcon}>
                                        <Link className='text-200 text-secondary' style={{ fontFamily: 'Poppins, sans-serif'}}>Sertu Certificate</Link>
                                    </Menu.Item>
                                    <Menu.Item className='mb-1' icon={SettingsIcon}>
                                        <Link to={"/setting"} className='text-200 text-secondary'>Setting</Link>
                                    </Menu.Item>
                                </Menu.Group>
                            }
                            
                            {
                                !CHECK_SESSION() ?
                                <Menu.Group>
                                    <Menu.Divider></Menu.Divider>
                                    <Menu.Item className='mb-1' icon={LogInIcon} intent='info'>
                                        <Link to={"/login"} replace className='text-200 text-primary'>Login</Link>
                                    </Menu.Item>
                                </Menu.Group> :
                                <Menu.Group background='tint1'>
                                    <Menu.Divider></Menu.Divider>
                                    <Menu.Item className='mb-1' icon={LogOutIcon} intent='danger'>
                                        <Link onClick={__Logout} className='text-200 text-danger'>
                                            Logout
                                        </Link>
                                    </Menu.Item>
                                </Menu.Group>
                            }
                        </Menu>
                    </Pane>
                </SideSheet>
            </React.Fragment>

            <section className='show-on-mobile py-1 w-100 bg-white shadow-sm' style={{ position: 'fixed', top: 0, zIndex: 10}}>
                <div className='row w-100 py-3 px-2 d-flex align-items-center'>
                    <div className='col-6 px-3'>
                        <Link to={"/"} className=''>
                            <img src={require("../assets/Logo/NLOGO.png")} className='mx-1' style={{ width: 50, height: 30 }} />
                        </Link>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <Button appearance='minimal' onClick={() => setIsShown(true)}>
                            <MenuIcon />
                        </Button>   
                    </div>
                </div>
            </section>

            <section className='hide-on-mobile px-3 py-2 w-100 bg-white shadow-sm' style={{ position: 'fixed', top: 0, zIndex: 10}}>
                {/* <div className='row w-100' style={{ borderBottom: '0.4px solid #f3f4f6', maxWidth: '1140px', margin: 'auto' }}>
                    <div className='col-6 py-2'>
                        <span className='text-150 fw-bold text-secondary px-1'>
                            copyright {new Date().getFullYear()} @ e-sertu.com 
                        </span>
                    </div>
                    <div className='col-6 d-flex align-items-center justify-content-end'>
                        <div>
                            <Link className='text-150 fw-bold text-secondary px-2'> 
                                <span>E-SERTU</span>
                            </Link>
                        </div>
                        <div>
                            <Link className='text-150 fw-bold text-secondary px-2'> 
                                <span>SIIETA</span>
                            </Link>
                        </div>
                        <div>
                            <Link className='text-150 fw-bold text-secondary px-2'> 
                                <span>About Us</span>
                            </Link>
                        </div>
                        <div>
                            <Link className='text-150 fw-bold text-secondary px-2'> 
                                <span>Halal</span>
                            </Link>
                        </div>
                    </div>
                </div> */}
                <div className='row w-100' style={{ position: 'sticky', top: 0, maxWidth: '1140px', margin: 'auto'}}>
                    <div className='col-sm-7 d-flex align-items-center'>
                        <Link to={"/"} className='d-flex align-items-center'>
                            <img src={require("../assets/Logo/NLOGO.png")} className='mx-1' style={{ width: 90, height: 60}} />
                            {/* <h6 className='mx-1'>e-sertu</h6> */}
                        </Link>
                        {/* <div style={{ marginLeft: 10 }}>
                            <Popover
                                position={Position.BOTTOM_RIGHT}
                                content={
                                    <Menu>
                                    <Menu.Group>
                                        {
                                            dropdown_service.map((data, index) => 
                                                <Menu.Item 
                                                    key={index} 
                                                    onSelect={() => toaster.notify(`${data.SERVICE_NAME}`)} 
                                                    className='text-200'
                                                    style={{ fontFamily: 'Poppins, sans-serif'}}
                                                >
                                                    {data.SERVICE_NAME}
                                                </Menu.Item>   
                                            )
                                        }
                                    </Menu.Group>
                                    </Menu>
                                }
                                >
                                <Button appearance='minimal' marginRight={0}>
                                    <span className='text-200 icon-primary fw-bold'>Services</span>
                                    <span style={{ marginLeft: 5}}><ChevronDownIcon className='icon-primary' size={14} /></span>
                                </Button>
                            </Popover>
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <Popover
                                position={Position.BOTTOM_RIGHT}
                                content={
                                    <Menu>
                                    <Menu.Group>
                                        {
                                            dropdown_service_categories.map((data, index) => 
                                                <Menu.Item 
                                                    key={index} 
                                                    onSelect={() => toaster.notify(`${data.SCATEGORY_NAME}`)}
                                                    className='text-200'
                                                    style={{ fontFamily: 'Poppins, sans-serif'}}
                                                >
                                                    {data.SCATEGORY_NAME}
                                                </Menu.Item>   
                                            )
                                        }
                                    </Menu.Group>
                                    </Menu>
                                }
                                >
                                <Button type='button' appearance='minimal' marginRight={0}>
                                    <span className='text-200 icon-primary fw-bold'>Categories</span>
                                    <span style={{ marginLeft: 5}}><ChevronDownIcon className='icon-primary' size={14} /></span>
                                </Button>
                            </Popover>
                        </div> */}
                        <div style={{ marginLeft: 10}}>
                            <Button onClick={() => routepush("/information")} appearance='minimal' marginRight={0}>
                                <span className='text-200 icon-primary fw-bold'>Sertu</span>
                            </Button>
                        </div>
                        <div style={{ marginLeft: 10}}>
                            <Button onClick={() => routepush("/find-operator")} appearance='minimal' marginRight={0}>
                                <span className='text-200 icon-primary fw-bold'>Operators</span>
                            </Button>
                        </div>
                    </div>
                    <div className='col-sm-5 d-flex align-items-center justify-content-end'>

                        {
                            CHECK_SESSION() && <Link to={"/sertu-authorization-letter"} className='fw-bold text-200 icon-primary px-2'>Authorization Letter</Link>
                        }

                        {
                            CHECK_SESSION() && <Link to={"/my-quotation"} className='fw-bold text-200 icon-primary px-2'>My Quotation</Link>
                        }

                        {
                            CHECK_SESSION() && 
                            <div className='d-flex align-item-center justify-content-center px-2'>
                                <div>
                                <Popover
                                    position={Position.BOTTOM_RIGHT}
                                    content={
                                        <Menu>
                                        <Menu.Group>
                                            <Menu.Item onClick={() => routepush("/")} icon={HomeIcon}>
                                                <Link to={"/"} className='text-200' style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                    Main
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item onClick={() => routepush("/my-quotation")} icon={DocumentIcon}>
                                                <Link to={"/my-quotation"} className='text-200' style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                    My Quotation (0)
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item onClick={() => routepush("#")} className='mb-1 mt-1' icon={BookIcon}>
                                                <Link to={"#"} className='text-200' style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                    Sertu Certificate
                                                </Link>
                                            </Menu.Item>
                                            <Menu.Item onClick={() => routepush("/setting")} icon={SettingsIcon}>
                                                <Link to={"/setting"} className='text-200' style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                    Setting
                                                </Link>
                                            </Menu.Item>
                                        </Menu.Group>
                                        <Menu.Divider />
                                        <Menu.Group>
                                            {
                                                CHECK_SESSION() ?
                                                <Menu.Item onClick={__Logout} intent='danger' className='text-200' style={{ fontFamily: 'Poppins, sans-serif'}} icon={LogOutIcon}>
                                                    <Icon name={LogOutIcon} size={16} /> Logout
                                                </Menu.Item> :
                                                <Menu.Item style={{ fontFamily: 'Poppins, sans-serif'}} icon={LogInIcon} intent='info'>
                                                    <Link to={"/login"} className='text-200 text-primary'>Login</Link>
                                                </Menu.Item>
                                            }
                                        </Menu.Group>
                                        </Menu>
                                    }
                                    >
                                        <Button appearance="minimal" className='active-none m-0 p-0'>
                                            <UserIcon color={color.primary} size={24} style={{ marginTop: 0, marginRight: 5}} />
                                            <span className='text-200' style={{ fontFamily: 'Poppins, sans-serif'}}>Hi, {GET_DATA().ACCOUNT_NAME}</span>
                                        </Button>
                                    </Popover> 
                                </div>
                            </div>
                        }

                        {
                            !CHECK_SESSION() && <Link to={"/login"} className='fw-bold text-200 icon-primary'>Login</Link>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default TopHeader;

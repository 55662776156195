import React, { useEffect, useState } from 'react';
import TailwindBackground from '../components/TailwindBackground';
import TopHeader from '../components/TopHeader';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { API } from '../utils/helper';
import { Pane, Spinner, toaster } from 'evergreen-ui';
import { color } from '../constant';
import Lottie from 'react-lottie';
import Footer from '../components/Footer';

function Receipt(props) {

    // SAMPLE CALLBACK
    // https://e-sertu.com/receipt?status_id=3&billcode=i020jz4w&order_id=ES827171911288704&msg=ok&transaction_id=TP2308301132139506

    const search                    = useLocation().search
    const searchParams              = new URLSearchParams(search)
    const [loading, set_loading]    = useState(false)

    const [content_receipt, set_content_receipt] = useState({})

    useEffect(() => {
        GET__CONTENT__RECEIPT()
    }, [])

    const GET__CONTENT__RECEIPT = async () => {
        set_loading(true)
        let api = await API("payment/receipt", { INVOICE_NO: searchParams.get("order_id") }, "POST", true)
        console.log("Log Function Get Content Receipt : ", api)

        if(api.status_code === 200) {
            set_content_receipt(api.data)
        } else {
            toaster.danger("Failed To Get Receipt!", { description: api.message })
        }

        set_loading(false)
    }

    return (
        <div>
            <TopHeader />
            <div className='mt-[70px]'>
                <TailwindBackground>
                    <div className='text-center'>

                        {
                            loading &&
                            <Spinner size={36} color={color.gray} />
                        }

                        {
                            !loading && content_receipt.BILLPAYMENT_NAME &&
                            <Pane>
                                <h1 className='font-bold text-5xl'>{ content_receipt.BILLPAYMENT_STATUS == 1 ? 'Payment Success!' : 'Payment Unsuccess!'}</h1>
                                <span>Below are all the information about your payment receipt.</span>

                                <div className='my-3'>
                                {
                                    content_receipt.BILLPAYMENT_STATUS == 1 ?
                                    <Lottie 
                                        options={{
                                            loop: true,
                                            autoplay: true, 
                                            animationData: require('../assets/Lottie/payment_success.json'),
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={150}
                                        width={150}
                                    /> :
                                    <Lottie 
                                        options={{
                                            loop: true,
                                            autoplay: true, 
                                            animationData: require('../assets/Lottie/payment_failed.json'),
                                            rendererSettings: {
                                                preserveAspectRatio: 'xMidYMid slice'
                                            }
                                        }}
                                        height={150}
                                        width={150}
                                    /> 
                                }
                                </div>

                                <div className='mt-3'>
                                    <div className='bg-white p-3 rounded max-w-[786px] m-auto border shadow-sm'>

                                        <div className='px-3 mt-3 flex justify-between'>
                                            <div className='text-left'>
                                                <p className='font-bold text-xl'>{content_receipt.BILLPAYMENT_NAME}</p>
                                                <p className='text-gray-400'>{content_receipt.BILLPAYMENT_DESCRIPTION}</p>
                                            </div>
                                            <div>
                                                <p className='font-bold text-xl'>Reference No:</p>
                                                <p className='text-gray-400'>{searchParams.get("order_id")}</p>
                                            </div>
                                        </div>

                                        <div className='mt-3'>
                                            <img src={content_receipt.OPERATOR_LOGO} className='m-auto w-[300px] h-[300px] object-contain' />
                                        </div>

                                        <div className='px-3 mt-1 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Payor Name</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>{content_receipt.BILLPAYMENT_PAYOR_NAME}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Payor Email</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>{content_receipt.BILLPAYMENT_PAYOR_EMAIL}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Payor Phone</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>{content_receipt.BILLPAYMENT_PAYOR_PHONE}</p>
                                            </div>
                                        </div>

                                        <div className='mx-3 my-4 border'></div>

                                        <div className='px-3  flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Operator Name</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>{content_receipt.OPERATOR_NAME}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Operator Email</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>{content_receipt.OPERATOR_EMAIL}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Operator Phone</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>{content_receipt.OPERATOR_PHONE}</p>
                                            </div>
                                        </div>

                                        <div className='mx-3 my-4 border'></div>

                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Payment Method</p>
                                            </div>
                                            <div>
                                                <p className='font-bold text-gray-600'>{content_receipt.BILLPAYMENT_TYPE}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-3 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Sub Total</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>RM {parseFloat(content_receipt.BILLPAYMENT_AMOUNT).toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>SST (6%)</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>RM {parseFloat(0.00).toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 mt-2 flex justify-between'>
                                            <div>
                                                <p className='font-bold'>Discount</p>
                                            </div>
                                            <div>
                                                <p className='text-gray-400'>RM {parseFloat(0.00).toFixed(2)}</p>
                                            </div>
                                        </div>
                                        <div className='px-3 py-2 mt-2 flex justify-between bg-gray-100 rounded items-center'>
                                            <div>
                                                <p className='font-bold'>Total</p>
                                            </div>
                                            <div>
                                                <p className='font-bold text-2xl text-emerald-600'>RM {parseFloat(content_receipt.BILLPAYMENT_AMOUNT).toFixed(2)}</p>
                                            </div>
                                        </div>

                                        <div className='w-full my-5 text-center'>
                                            <p className='m-auto max-w-[500px] text-center text-gray-400 text-xs'>This receipt has been automatically generated by our computerized system and is provided for informational purposes only.</p>
                                        </div>

                                    </div>
                                </div>
                            </Pane>
                        }

                        {
                            !loading && !content_receipt.BILLPAYMENT_NAME &&
                            <Pane>
                                <h1 className='font-bold text-5xl'>Receipt Not Found.</h1>
                                <span>We are unable to get your receipt.</span>

                                <div className='mt-3'>
                                    <div className='bg-white p-3 rounded max-w-[786px] m-auto border shadow-sm'>
                                        <span>No receipt content found.</span>
                                    </div>
                                </div>
                            </Pane>
                        }
                    </div>
                </TailwindBackground>
            </div>
            <Footer bg='transparent' />
        </div>
    );
}

export default Receipt;
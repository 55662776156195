import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API } from '../utils/helper';
import GlobalLoading from '../components/GlobalLoading';
import { Pane, Spinner, TextInput } from 'evergreen-ui';
import TopHeader from '../components/TopHeader';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon, CalendarIcon } from '@heroicons/react/24/outline';
import Footer from '../components/Footer';
import TailwindBackground from '../components/TailwindBackground';
import { color } from '../constant';
import moment from 'moment';

SertuAuthorizationLetter.propTypes = {
    
};

function SertuAuthorizationLetter(props) {

    const [loading, set_loading]                    = useState(true)
    const [list_auth_letter, set_list_auth_letter]  = useState([])

    useEffect(() => {
        GET__DATA()
    }, [])

    const GET__DATA = async () => {
        set_loading(true)
        let api = await API("sertu-authorization-letter/list", {}, "GET", true)
        console.log("Log Get Data : ", api)
        if(api.status_code === 200) {
            set_list_auth_letter(api.data)
        }
        set_loading(false)
    }

    return (
        <div>
        
            <div className='row'>
                <div className='col-12'>
                    
                    <TopHeader />

                    <section className='show-on-mobile p-3' style={{ marginTop: '100px'}}>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='mb-2'>
                                    <Link to={-1} replace>
                                        <ArrowLeftIcon size={24} color={color.black} />
                                    </Link>
                                </div>
                                <div className='mt-3 small-line-height'>
                                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-7xl">e-sertu</p>
                                    <span className='text-150 text-secondary'>Our goals is to provide you the best cleaning service with the best price for you.</span>
                                </div>
                                <div className='mt-3'>
                                    <TextInput size='large' className='w-100' placeholder='Search quotation...' />
                                </div>
                                <div className='mt-3'>
                                {
                                    loading && 
                                    <div className='row'>
                                        <div className="col-12 text-secondary">
                                            <Spinner size={24} color={color.gray} />
                                        </div>
                                    </div>
                                }

                                {
                                    loading === false && list_auth_letter.length < 1 &&
                                    <div className='row'>
                                        <div className="col-12 text-secondary">
                                            <span className='text-150 text-secondary'>No data found.</span>
                                        </div>
                                    </div>
                                }

                                {
                                    loading === false && list_auth_letter.length > 0 && list_auth_letter.map((data, index) =>
                                    <Pane 
                                        key={index} 
                                        backgroundColor='tint1' 
                                        className='card p-3 mb-2 w-100'
                                        //onClick={() => NavigateRoute(data)}
                                    >
                                        <div className='small-line-height'>
                                            <h6 className='text-200'>
                                                {data.LETTER_TITLE.length > 60 ? data.LETTER_TITLE.substr(0, 57) + "..." : data.LETTER_TITLE}
                                            </h6>
                                            <span className='text-150'>• {data.LETTER_CODE} Reqeust Quotation</span><br />
                                            <span className='text-150'>• {data.LETTER_CODE} Reqeust Quotation</span><br />
                                            <span className='text-150 text-secondary text-align-center'><CalendarIcon size={10} /> Date Created: {data.CREATED_DATE}</span>
                                        </div>
                                    </Pane>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className='hide-on-mobile' style={{ marginTop: 70}}>
                        <TailwindBackground>
                        <div className='col-12 small-line-height'>
                            <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl">My Sertu Authorization Letter</p>
                            <span className='mt-6 text-sm leading-8 text-gray-500'>Our goals is to provide you the best cleaning service with the best price for you.</span>
                        </div>
                        <div className='row mt-4' style={{ transition: '0.5s'}}>
                            <div className='col-12 col-sm-12 col-md-12'>
                                <div className='card shadow-sm px-4 py-2' style={{ transition: '0.5s'}}>
                                <section className="container px-4 mx-auto mt-3 pb-3">
                                    <div className="sm:flex sm:items-center sm:justify-between">
                                        <div>
                                            <div className="flex items-center gap-x-3">
                                                <h2 className="text-lg font-medium text-gray-800">My Sertu Authorization Letter</h2>
                                                <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full">{list_auth_letter.length} data found.</span>
                                            </div>
                                            <p className="mt-1 text-sm text-gray-500">Below are list of your request quotations.</p>
                                        </div>
                                    </div>
                                    <div className="mt-6 md:flex md:items-center md:justify-end">
                                        <div className="relative flex items-center xs:mt-4 sm:mt-0">
                                            <span className="absolute">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-3 text-gray-400">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                                </svg>
                                            </span>
                                            <input type="text" placeholder="Search" className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11" />
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-6">
                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                                <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                                                    <table className="min-w-full divide-y divide-gray-200">
                                                        <thead className="bg-gray-50">
                                                            <tr>
                                                                <th scope="col" className="py-3.5 px-4 text-sm font-normal text-gray-500">
                                                                    <span>No.</span>
                                                                </th>
                                                                <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                    Letter Title
                                                                </th>
                                                                <th scope="col" className="px-12 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500">
                                                                    URL
                                                                </th>
                                                                <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                    Date Created
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-white divide-y divide-gray-200">
                                                            {
                                                                list_auth_letter.length > 0 && list_auth_letter.map((data, index) => 
                                                                <tr key={index}>
                                                                    <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                                        <span className='text-sm'>{index + 1}.</span>
                                                                    </td>
                                                                    <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                        <p style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>{data.LETTER_TITLE}</p>
                                                                        <p style={{ fontFamily: 'Poppins, sans-serif'}} className='text-xs'>{data.LETTER_CODE}</p>
                                                                    </td>
                                                                    <td className="px-4 py-4 text-sm whitespace-nowrap text-center">
                                                                        {/* <span className='px-3 py-2 bg-gray-300 rounded' style={{ fontFamily: 'Poppins, sans-serif'}}>{data.LETTER_URL}</span> */}
                                                                        <a target='blank' href={data.LETTER_URL} className='underline'>{data.LETTER_URL}</a>
                                                                    </td>
                                                                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                        <span style={{ fontFamily: 'Poppins, sans-serif'}}>{moment(data.CREATED_DATE).format("DD MMM YYYY H:mm A")}</span>
                                                                    </td>
                                                                </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 flex items-center sm:justify-between ">
                                        <div className="text-sm text-gray-500 dark:text-gray-400">
                                            Page <span className="font-medium text-gray-700 dark:text-gray-100">1 of 10</span> 
                                        </div>
                                        <div className="flex items-center gap-x-4 sm:mt-0">
                                            <button type='button' className="flex items-center justify-center w-1/2 px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                                </svg>
                                            </button>
                                            <button type='button' className="flex items-center justify-center w-1/2 px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </section>
                                </div>
                            </div>
                        </div>
                        </TailwindBackground>
                    </section>
                    <Footer bg='transparent' />
                </div>
            </div>
        </div>
    );
}

export default SertuAuthorizationLetter;
const jwt = require('react-jwt');

export const VERIFY_TOKEN = (TOKEN) => {
    //let verifyToken = jwt.verify(TOKEN, process.env.ACCESS_SECRET_KEY)
    let verifyToken = jwt.decodeToken(TOKEN)
    return verifyToken;
}

export const CHECK_SESSION = () => {

    let result  = null
    let token   = window.localStorage.getItem("_aT")

    if(!token || token === undefined || token === "{}") {
        result = false
    } else {
        let data    = jwt.decodeToken(token)
        try {
            if(data.ACCOUNT_SECRET_KEY) {
                result = true
            } else {
                result = false
            }
        } catch(e) {
            result = false
        }
    }
    
    return result
}

export const GET_DATA = () => {

    let result  = null
    let token   = window.localStorage.getItem("_aT")

    if(!token || token === undefined || token === "{}") {
        result = false
    } else {
        let data    = jwt.decodeToken(token)
        result      = data
    }
    
    return result
}

export const ENCRYTP = (data) => {
    return btoa(data);
}
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Pane, Spinner } from 'evergreen-ui';
import { color } from '../constant';
import TailwindBackground from '../components/TailwindBackground';
import TailwindBackground2 from '../components/TailwindBackground2';
import TopHeader from '../components/TopHeader';
import { API } from '../utils/helper';
import { Document, Page, Text, View, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

import FullBackground from '../assets/background.png'
import BlurBackground from '../assets/blur-background.png'
import moment from 'moment';
import Footer from '../components/Footer';

SertuCertificate.propTypes = {
    
};

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#ffffff',
	},
	section: {
		position: 'relative',
		flexGrow: 1,
		textAlign: 'center',
	},
	background: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 1, 
	},
	qrCode: {
		position: 'absolute',
		top: 40,
		right: 20,
	},
	title: {
		top: 330,
		fontSize: 28,
		fontWeight: 'bold',
		marginBottom: 20,
		color: '#333',
	},
	content: {
		top: 360,
	},
	requestor: {
		position: 'absolute',
		bottom: 270,
		marginBottom: 10,
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#333',
		lineHeight: 1.9,
	},
    penama: {
        position: 'absolute',
		top: 440,
		// fontFamily: 'Open Sans',
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#333',
		lineHeight: 1.9,
	},
	address: {
        position: 'absolute',
		top: 500,
		// fontFamily: 'Open Sans',
		fontSize: 10,
		fontWeight: 'bold',
		textAlign: 'center',
		color: '#333',
		lineHeight: 1.9,
	},
	topLeftText: {
		position: 'absolute',
		top: 40,
		left: 110,
		fontSize: 8,
		color: '#777',
		lineHeight: 1.9,
	},
	topRightText: {
		position: 'absolute',
		top: 50,
		right: 55,
		fontSize: 8,
		color: '#777',
	},
	footer: {
		position: 'absolute',
		bottom: 20,
		left: 20,
		right: 20,
		textAlign: 'center',
		color: '#777',
	},

	logo: {
		position: 'absolute',
		bottom: 90,
		left: 90,
		width: 100,
		height: 'auto',
	},
});

function SertuCertificate(props) {

    const navigation    = useNavigate()
    const state         = useLocation().state

    const [loading, set_loading]        = useState(true)
    const [quotation, set_quotation]    = useState(null)

    useEffect(() => {
        GET__QUOTATION()
    }, [])

    const GET__QUOTATION = async () => {
        set_loading(true)
        let json = { QUOTATION_ID: state.QID, OQFORM_ID: state.OQFORM_ID }
        let api = await API("sertu-certificate", json, "POST", true)
        //console.log("Log Get Certificate : ", api)
        if(api.status_code === 200) {
            set_quotation(api.data)
        }
        set_loading(false)
    }

    if(loading) {
        return (
            <TailwindBackground2>
                <Pane style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                    <Spinner size={100} color={color.primary} borderColor={color.primary} />
                </Pane>
            </TailwindBackground2>)
    }

    return (
        <div>
            <TopHeader />
            <TailwindBackground>
                <section className='mt-[70px]'>
                    <div className='col-12 small-line-height'>
                        <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl">My Sertu Certificate</p>
                        <span className='mt-6 text-sm leading-8 text-gray-500'>Our goals is to provide you the best cleaning service with the best price for you.</span>
                    </div>
                </section>

                {
                    quotation === null && (
                        <section style={{ marginTop: 16}}>
                            <Pane className=''>
                                <Alert intent='danger' title="Sertu Certificate Unavailable">
                                    We are sorry that your sertu certificate is not available yet. Please wait while your sertu request to be complete or please make full payment for your quotation.
                                </Alert>
                            </Pane>
                        </section>
                    )
                }

                {
                    quotation !== null && (
                        <section style={{ marginTop: 16}}>
                            <Pane className=''>
                                <Alert intent='success' title="Sertu Certificate Available">
                                    You sertu certificate will appear below in just a few second. Please wait while system is rendering your certificate.
                                </Alert>
                            </Pane>
                        </section>
                    )
                }

                {
                    quotation !== null && (
                        <section style={{ marginTop: 24 }}>
                            <div style={{ width: '21cm', maxWidth: '24cm', height: '29.7cm', margin: 'auto' }}>
                            <PDFViewer 
                                width="100%" 
                                height="100%" 
                                showToolbar={false}
                            >
                                <Document 
                                language='english' 
                                author='E-SERTU'
                                creator='E-SERTU'
                                >
                                    <Page size="A4" style={{...styles.page}}>
                                        <View style={styles.section}>
                                        <Image src={FullBackground} style={styles.background} />

                                        <Text style={styles.topLeftText}>
                                        MS 2200 : {new Date().getFullYear()}
                                        {'\n'}{moment(quotation.QUOTATION_LAST_MODIFIED).format("DD/MM/YYYY")}
                                        {'\n'}{moment(quotation.QUOTATION_LAST_MODIFIED).add(1, "year").format("DD/MM/YYYY")}
                                        </Text>

                                        <Text style={styles.topRightText}>{quotation.QUOTATION_CODE}</Text>
                                        <Text style={styles.title}>{quotation.QSERVICE}</Text>

                                        <View style={styles.content}>
                                            <View>
                                                <Text style={{ fontSize: 18, fontWeight: 'bold', color: 'black'}}>{quotation.QUOTATION_OWNER_NAME}</Text>
                                            </View>
                                            <View style={{ maxWidth: 300, alignItems: 'center', justifyContent: 'center', alignSelf: 'center' }}>
                                                <Text style={{ marginTop: 7, fontSize: 10, fontWeight: 'normal', color: 'black', textAlign: 'center'}}>{quotation.QUOTATION_REQUEST_ADDRESS}</Text>
                                            </View>
                                        </View>

                                        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={styles.penama}>{quotation.OPERATOR_NAME}</Text>
                                        </View>

                                        <Image src={{ uri: quotation.OPERATOR_LOGO }} style={styles.logo} />
                                        
                                        </View>
                                    </Page>
                                </Document>
                            </PDFViewer>
                            </div>
                        </section>
                    )
                }

                <Footer bg='transparent' />
            </TailwindBackground>
        </div>
    );
}

export default SertuCertificate;
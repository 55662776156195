import React from 'react';
import { Font, Image, PDFViewer as PDF } from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { color } from '../constant';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

function PDFViewer({ data = null }) {

    //const data = useLocation().state;

    // Create styles
    const styles = StyleSheet.create({
        page: {
            //margin: 'auto',
            backgroundColor: 'white',
            //height: '297px',
            marginTop: 24,
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 24,
            width: '100%'
        },
        section: {
        },
        subtitle: {
            fontSize: 18,
            margin: 12,
            fontFamily: 'Poppins',
        },
        text: {
            margin: 10,
            fontSize: 14,
            fontFamily: 'Poppins',
        },
        image: {
            marginVertical: 15,
            marginHorizontal: 100,
        },
        header: {
            fontSize: 12,
            marginBottom: 20,
            textAlign: 'center',
            color: 'grey',
        },
    });   

    // Register fonts
    Font.register({ 
        family: 'Poppins', 
        fonts: [
            { 
                src: 'https://e-sertu.com/docs/Poppins-Regular.ttf', 
                fontWeight: 'normal' 
            },
            { 
                src: 'https://e-sertu.com/docs/Poppins-SemiBold.ttf', 
                fontWeight: 'bold' 
            }
        ] 
    });

    return (
        <PDF 
        style={{
            margin: 'auto',
            width: '100%',
            height: window.innerHeight,
            //maxWidth: '210mm',
            //maxHeight: '297mm'
        }}>
            <Document 
                title='Global Dibagh Sertu Quotation.pdf'
                author='Global Dibagh Sdn. Bhd.'
                subject='Sertu Quotation'
                pdfVersion='1.0.0'
                producer='e-sertu.com'
                key={new Date().getTime()}
                keywords='Sertu Quotation'
                creator='e-sertu.com'
                language="english"
                //pageMode="fullScreen"
                //pageLayout="singlePage"
                style={{
                    padding: 36,
                    marginTop: 14
                }}
            >
                <Page 
                    size="A4"
                    id={new Date().getTime()}
                    style={{
                        margin: 20,
                        width: '100%',
                        height: '100%'
                    }}
                    break
                    //debug={process.env.NODE_ENV === "development" ? true : false}
                >
                    {/* MAKLUMAT OPERATOR DAN LOGO OPERATOR BOLEH DI LETAK DI SINI */}
                    <View style={{...styles.section, flexDirection: 'row', marginRight: 36}} break={false}>
                        <View style={{ width: '40%' }}>
                            <Image 
                                src={data.OPERATOR_LOGO}
                                style={{
                                    width: 150,
                                    height: 150,
                                    objectFit: 'contain'
                                }}
                                cache
                            />
                        </View>
                        <View style={{ width: '60%' , paddingHorizontal: 16, flexWrap: 'wrap' }}>
                            <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>{data.OPERATOR_NAME}</Text>
                            <Text style={{ fontSize: 11, color: color.darkgray, fontFamily: 'Poppins', lineHeight: 1.2 }}>
                                {data.OPERATOR_ADDRESS}
                            </Text>

                            <View style={{ marginTop: 16 }}>
                                <Text style={{ fontSize: 9, color: color.gray, fontFamily: 'Poppins' }}>Tel: {data.OPERATOR_PHONE}</Text>
                                <Text style={{ fontSize: 9, color: color.gray, fontFamily: 'Poppins' }}>Fax: +603 - 8926 5826</Text>
                                <Text style={{ fontSize: 9, color: color.gray, fontFamily: 'Poppins' }}>Email: {data.OPERATOR_EMAIL}</Text>
                            </View>
                        </View>
                    </View>

                    {/* MAKLUMAT REQUESTOR BOLEH FETCH DAN LETAK DI SINI */}
                    <View style={{
                        marginTop: 16,
                        marginRight: 40,
                        flexDirection: 'row',
                    }}>
                        <View style={{ flex: 1, backgroundColor: color.lightgray, padding: 16, borderRadius: 7 }}>
                            <View style={{
                                flexDirection: 'row'
                            }}>
                                <View style={{ flex: 0.2}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black }}>To</Text>
                                </View>
                                <View style={{ flex: 0.8}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray }}>
                                        {data.QUOTATION_OWNER_NAME}
                                    </Text>
                                </View>
                            </View>
                            <View style={{
                                marginTop: 7,
                                flexDirection: 'row'
                            }}>
                                <View style={{ flex: 0.2}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black }}>Address</Text>
                                </View>
                                <View style={{ flex: 0.8}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray }}>
                                        {data.QUOTATION_REQUEST_ADDRESS}
                                    </Text>
                                </View>
                            </View>
                            <View style={{
                                marginTop: 7,
                                flexDirection: 'row'
                            }}>
                                <View style={{ flex: 0.2}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black }}>Tel / Fax</Text>
                                </View>
                                <View style={{ flex: 0.8}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray }}>
                                        {data.QUOTATION_OWNER_PHONE}
                                    </Text>
                                </View>
                            </View>
                            <View style={{
                                marginTop: 7,
                                flexDirection: 'row'
                            }}>
                                <View style={{ flex: 0.2}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black }}>Email</Text>
                                </View>
                                <View style={{ flex: 0.8}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray }}>
                                        {data.QUOTATION_OWNER_EMAIL}
                                    </Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ flex: 0.4, backgroundColor: color.lightgray, marginLeft: 7, padding: 16, borderRadius: 7 }}>
                            <View>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black }}>Date</Text>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray }}>{moment(data.OQFORM_REQUEST_DATE).format('DD-MMMM-YYYY')}</Text>
                            </View>
                            <View style={{ flex: 1, flexWrap: 'wrap', marginTop: 7 }}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black }}>Quotation No:</Text>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray }}>{data.OQFORM_CODE}</Text>
                            </View>
                        </View>
                    </View>
                    
                    
                    {/* TAJUK QUOTATION YANG DI SET OLEH OPERATOR */}
                    <View style={{ borderRadius: 7, marginTop: 16, marginRight: 40 }}>
                        <Text style={{
                            fontFamily: 'Poppins', 
                            fontWeight: 'bold', 
                            fontSize: 16, 
                            color: color.black,
                            lineHeight: 1.2
                            
                        }}>
                            {(data.OQFORM_NAME).toLocaleUpperCase()} 
                        </Text>
                    </View>

                    {/* TABLE OF CONTENT - TABLE WILL DISPLAY ABOUT TABLE QUOTATION ITEMS INCLUDING DESCRIPTION, QUANTITY, PRICE */}
                    <View style={{borderRadius: 7, marginTop: 24, marginRight: 40 }}>

                        <View style={{ flexDirection: 'row', backgroundColor: color.lightgray }}>
                            <View style={{ flex: 0.5, padding: 7}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}>No.</Text>
                            </View>
                            <View style={{ flex: 6, padding: 7}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                    Description of Work, List, Material
                                </Text>
                            </View>
                            <View style={{ flex: 1, padding: 7}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}>
                                    Qty
                                </Text>
                            </View>
                            <View style={{ flex: 2, padding: 7}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}>Price (RM)</Text>
                            </View>
                        </View>
                        
                        {
                            data.Q_ITEM !== null && data.Q_ITEM.length > 0 && data.Q_ITEM.map((item, index) => 
                            <View style={{ flexDirection: 'row', border: 0.5, borderColor: '#d1d5db' }}>
                                <View style={{ flex: 0.5, padding: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>{index + 1}</Text>
                                </View>
                                <View style={{ flex: 6, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>
                                        {item.NAME} - {item.DESCRIPTION}
                                    </Text>
                                </View>
                                <View style={{ flex: 1, padding: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray , textAlign: 'center'}}>
                                        {item.QUANTITY}
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7, alignItems: 'center', justifyContent: 'center'}}>
                                    <Text style={{ 
                                        fontFamily: 'Poppins', 
                                        fontWeight: 'bold',
                                        fontSize: 12, 
                                        color: color.black , 
                                        textAlign: 'center'
                                    }}>{parseFloat(item.AMOUNT).toFixed(2)}</Text>
                                </View>
                            </View>
                            )
                        }

                        
                        {/* <View style={{ flexDirection: 'row', border: 0.5, borderColor: '#d1d5db' }}>
                            <View style={{ flex: 0.5, padding: 7, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>2.</Text>
                            </View>
                            <View style={{ flex: 6, padding: 7}}>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>
                                    Spray Injector - Required as to get a better clean surface.
                                </Text>
                            </View>
                            <View style={{ flex: 1, padding: 7, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray , textAlign: 'center'}}>
                                    1
                                </Text>
                            </View>
                            <View style={{ flex: 2, padding: 7, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{ 
                                    fontFamily: 'Poppins', 
                                    fontWeight: 'bold',
                                    fontSize: 12, 
                                    color: color.black , 
                                    textAlign: 'center'
                                }}>400.00</Text>
                            </View>
                        </View> */}


                        <View style={{ marginTop: 16 }}>
                            <View style={{ flexDirection: 'row'}}>
                                <View style={{ flex: 0.5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}></Text>
                                </View>
                                <View style={{ flex: 5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                        
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', color: color.gray, fontSize: 11}}>
                                        Sub Total
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'right'}}>
                                        RM {parseFloat(data.OQFORM_AMOUNT).toFixed(2)}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row'}}>
                                <View style={{ flex: 0.5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', color: color.gray, fontSize: 11, textAlign: 'center'}}></Text>
                                </View>
                                <View style={{ flex: 5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                        
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', color: color.gray, fontSize: 11}}>
                                        SST(6%)
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'right'}}>
                                        RM {parseFloat(data.OQFORM_SST ? parseFloat(data.OQFORM_AMOUNT) * 0.06 : 0.00).toFixed(2)}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row'}}>
                                <View style={{ flex: 0.5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}></Text>
                                </View>
                                <View style={{ flex: 5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                        
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', color: color.gray, fontSize: 11}}>
                                        Discount({data.OQFORM_DISCOUNT}%)
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'right'}}>
                                        - RM {parseFloat(parseFloat(data.OQFORM_AMOUNT) * (parseFloat(data.OQFORM_DISCOUNT) / 100)).toFixed(2)}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: color.lightgray, borderRadius: 3}}>
                                <View style={{ flex: 0.5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}></Text>
                                </View>
                                <View style={{ flex: 5, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                        
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                        Total Price
                                    </Text>
                                </View>
                                <View style={{ flex: 2, padding: 7}}>
                                    <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 14, textAlign: 'right'}}>
                                        RM {parseFloat(data.OQFORM_AMOUNT) + (data.OQFORM_SST ? parseFloat(data.OQFORM_AMOUNT) * 0.06 : 0.00) - (parseFloat(data.OQFORM_DISCOUNT) / 100)}
                                    </Text>
                                </View>
                            </View>
                        </View>

                    </View>
                </Page>

                <Page 
                    size="A4"
                    id={new Date().getTime()}
                    style={{
                        margin: 24
                    }}
                >
                    {/* TABLE OF CONTENT - TABLE WILL DISPLAY ABOUT TABLE QUOTATION ITEMS INCLUDING DESCRIPTION, QUANTITY, PRICE */}
                    <View style={{borderRadius: 7, marginRight: 48}}>

                        <View style={{ flexDirection: 'row', backgroundColor: color.lightgray }}>
                            <View style={{ flex: 0.5, padding: 7}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, textAlign: 'center'}}>No.</Text>
                            </View>
                            <View style={{ flex: 9.5, padding: 7, alignItems: 'center'}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11}}>
                                    Terms & Condition
                                </Text>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', border: 0.5, borderColor: '#d1d5db' }}>
                            <View style={{ flex: 0.5, padding: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>a.</Text>
                            </View>
                            <View style={{ flex: 9.5, padding: 10}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black}}>
                                    Sertu Competency in Halal QAS
                                </Text>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>
                                    The above Sertu works price is based on our company competency and our capability in managing
                                    Halal Conformity matters via regulations and system compliant.
                                </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', border: 0.5, borderColor: '#d1d5db' }}>
                            <View style={{ flex: 0.5, padding: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>b.</Text>
                            </View>
                            <View style={{ flex: 9.5, padding: 10}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black}}>
                                    Charge, rates and validity of quotations 
                                </Text>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>
                                    The above pricing are in Ringgit Malaysia and valid for a period of 30 days from date of this quotation. 
                                </Text>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', border: 0.5, borderColor: '#d1d5db' }}>
                            <View style={{ flex: 0.5, padding: 10, alignItems: 'center', justifyContent: 'center'}}>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>c.</Text>
                            </View>
                            <View style={{ flex: 9.5, padding: 10}}>
                                <Text style={{ fontFamily: 'Poppins', fontWeight: 'bold', fontSize: 11, color: color.black}}>
                                    Terms of Payment
                                </Text>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>
                                    ** 50% of downpayment upon issues of order purchased. The down payment should pay to GDSB account at least 7 days before undergo sertu process.
                                </Text>
                                <Text style={{ fontFamily: 'Poppins', fontSize: 10, color: color.gray}}>
                                    ** 50% of balance upon receiving of Sertu validation letter from Halal State Authority.
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={{
                        marginTop: 36,
                        marginRight: 40
                    }}>
                        <Text style={{
                            fontFamily: 'Poppins',
                            fontSize: 7,
                            color: color.gray,
                            textAlign: 'center'
                        }}>
                            This quotation is computer generated. It does not require any signature.
                        </Text>
                        <Text style={{
                            fontFamily: 'Poppins',
                            fontSize: 7,
                            color: color.gray,
                            textAlign: 'center'
                        }}>
                            copyrights @ {new Date().getFullYear()} e-sertu.com
                        </Text>
                    </View>
                </Page>
            </Document>
        </PDF>
    );
}

export default PDFViewer;
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { ArrowLeftIcon, Badge, Button, CalendarIcon, DocumentOpenIcon, Pagination, Pane, Spinner, Table, TextInput } from 'evergreen-ui';
import { color } from '../constant';
import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';
import { API } from '../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import TailwindBackground from '../components/TailwindBackground';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, EyeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

const MyQuotation = () => {

    let navigate = useNavigate()

    const [username, set_username]  = useState("")
    const [email, set_email]        = useState("")
    const [phone, set_phone]        = useState("")


    const [loading_api, set_loading_api]        = useState(true)
    const [list_quotation, set_list_quotation]  = useState([])

    useEffect(() => {

    }, [])

    useMemo(async () => {
        set_loading_api(true)
        let api = await API("quotation/list", {}, "POST", true)
        if(api.status_code === 200) {
            set_list_quotation(api.data)
            set_loading_api(false)
        } else {
            set_list_quotation([])
            set_loading_api(false)
        }
    }, [])

    const NavigateRoute = (data) => {
        navigate(`/quotation/${btoa(data.QUOTATION_CODE)}`, { state: data })
    }

    const GetQuotationStatus = (status) => {
        if(status === 0 ) {
            return "Deleted"
        } else if(status === 1) {
            return "Active"
        } else if(status === 2) {
            return "Pending Request"
        } else if(status === 3) {
            return "Cancel"
        } else if(status === 4) {
            return "Complete"
        } else if(status === 5) {
            return "Comfirm Booking"
        } else if(status === 6) {
            return "In-Progress"
        }
    }

    const GetQuotationStatusColor = (status) => {
        if(status === 0 ) {
            return "red"
        } else if(status === 1) {
            return "blue"
        } else if(status === 2) {
            return "yellow"
        } else if(status === 3) {
            return "red"
        } else if(status === 4) {
            return "green"
        } else if(status === 5) {
            return "teal"
        } else if(status === 6) {
            return "orange"
        }
    }

    return (
        <div className='row'>
            <div className='col-12'>
                
                <TopHeader />

                <section className='show-on-mobile p-3' style={{ marginTop: '100px'}}>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='mb-2'>
                                <Link to={-1} replace>
                                    <ArrowLeftIcon size={24} color={color.black} />
                                </Link>
                            </div>
                            <div className='mt-3 small-line-height'>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-7xl">e-sertu</p>
                                <span className='text-150 text-secondary'>Our goals is to provide you the best cleaning service with the best price for you.</span>
                            </div>
                            <div className='mt-3'>
                                <TextInput size='large' className='w-100' placeholder='Search quotation...' />
                            </div>
                            <div className='mt-3'>
                            {
                                loading_api && 
                                <div className='row'>
                                    <div className="col-12 text-secondary">
                                        <Spinner size={24} color={color.gray} />
                                    </div>
                                </div>
                            }

                            {
                                loading_api === false && list_quotation.length < 1 &&
                                <div className='row'>
                                    <div className="col-12 text-secondary">
                                        <span className='text-150 text-secondary'>No data found.</span>
                                    </div>
                                </div>
                            }

                            {
                                loading_api === false && list_quotation.length > 0 && list_quotation.map((data, index) =>
                                <Pane 
                                    key={index} 
                                    backgroundColor='tint1' 
                                    className='card p-3 mb-2 w-100'
                                    onClick={() => NavigateRoute(data)}
                                >
                                    <div className='small-line-height'>
                                        <h6 className='text-200'>
                                            {data.QUOTATION_NAME.length > 60 ? data.QUOTATION_NAME.substr(0, 57) + "..." : data.QUOTATION_NAME}
                                        </h6>
                                        <span className='text-150'>• {data.QUOTATION_CODE} Reqeust Quotation</span><br />
                                        <span className='text-150'>• {data.QUOTATION_TYPE} Reqeust Quotation</span><br />
                                        <span className='text-150 text-secondary text-align-center'><CalendarIcon size={10} /> Date Created: {data.QDATE}</span>
                                    </div>
                                </Pane>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </section>

                <section className='hide-on-mobile' style={{ marginTop: 70}}>
                    <TailwindBackground>
                    <div className='col-12 small-line-height'>
                        <p className="mt-2 text-3xl font-bold tracking-tight sm:text-5xl">My Quotation</p>
                        <span className='mt-6 text-sm leading-8 text-gray-500'>Our goals is to provide you the best cleaning service with the best price for you.</span>
                    </div>
                    <div className='row mt-4' style={{ transition: '0.5s'}}>
                        <div className='col-12 col-sm-12 col-md-12'>
                            <div className='card shadow-sm px-4 py-2' style={{ transition: '0.5s'}}>
                            <section className="container px-4 mx-auto mt-3 pb-3">
                                <div className="sm:flex sm:items-center sm:justify-between">
                                    <div>
                                        <div className="flex items-center gap-x-3">
                                            <h2 className="text-lg font-medium text-gray-800">My Quotation</h2>
                                            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full">{list_quotation.length} operator</span>
                                        </div>
                                        <p className="mt-1 text-sm text-gray-500">Below are list of your request quotations.</p>
                                    </div>
                                </div>
                                <div className="mt-6 md:flex md:items-center md:justify-between">
                                    <div className="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse">
                                        <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm">
                                            All
                                        </button>
                                        <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm hover:bg-gray-100">
                                            Pending Request
                                        </button>
                                        <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm hover:bg-gray-100">
                                            In-Progress
                                        </button>
                                        <button className="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm hover:bg-gray-100">
                                            Complete
                                        </button>
                                    </div>
                                    <div className="relative flex items-center xs:mt-4 sm:mt-0">
                                        <span className="absolute">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mx-3 text-gray-400">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </span>
                                        <input type="text" placeholder="Search" className="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11" />
                                    </div>
                                </div>
                                <div className="flex flex-col mt-6">
                                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                            <div className="overflow-hidden border border-gray-200 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th scope="col" className="py-3.5 px-4 text-sm font-normal text-gray-500">
                                                                <span>No.</span>
                                                            </th>
                                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Quotation
                                                            </th>
                                                            <th scope="col" className="px-12 py-3.5 text-sm font-normal text-center rtl:text-right text-gray-500">
                                                                Status
                                                            </th>
                                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Date Created
                                                            </th>
                                                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="bg-white divide-y divide-gray-200">
                                                        {
                                                            list_quotation.length > 0 && list_quotation.map((data, index) => 
                                                            <tr key={index}>
                                                                <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                                                    <span className='text-sm'>{index + 1}.</span>
                                                                </td>
                                                                <td className="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                                                    <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>{data.QUOTATION_NAME}</span><br />
                                                                    <span className='text-150'>• {data.QUOTATION_CODE}</span><br />
                                                                    <span className='text-150'>• {data.QUOTATION_TYPE} Request Quotation</span>
                                                                </td>
                                                                <td className="px-4 py-4 text-sm whitespace-nowrap text-center">
                                                                    {/* <span className='px-3 py-2 bg-gray-300 rounded' style={{ fontFamily: 'Poppins, sans-serif'}}>{GetQuotationStatus(data.QUOTATION_STATUS)}</span> */}
                                                                    <Badge color={GetQuotationStatusColor(data.QUOTATION_STATUS)}>
                                                                        {GetQuotationStatus(data.QUOTATION_STATUS)}
                                                                    </Badge>
                                                                </td>
                                                                <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                    <span style={{ fontFamily: 'Poppins, sans-serif'}}>{data.QDATE}</span>
                                                                </td>
                                                                <td className="px-4 py-4 text-sm whitespace-nowrap">
                                                                <Menu as="div" className="inline-block text-left">
                                                                <div>
                                                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 text-sm font-semibold text-gray-900">
                                                                        <EllipsisVerticalIcon className="-mr-1 h-7 w-7 text-gray-700" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>
                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-20 z-100 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    <div className="py-1">
                                                                        <Menu.Item>
                                                                        {({ active }) => (
                                                                            <button 
                                                                                onClick={() => NavigateRoute(data)} 
                                                                                className={classNames(active ? 'flex bg-gray-100 text-gray-900' : 'flex text-gray-700','block px-4 py-2 text-sm')}>
                                                                                <span><EyeIcon width={20} className='mr-2' /></span>View Quotation
                                                                            </button>
                                                                        )}
                                                                        </Menu.Item>
                                                                    </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                                </Menu>
                                                                </td>
                                                            </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-6 flex items-center sm:justify-between ">
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                        Page <span className="font-medium text-gray-700 dark:text-gray-100">1 of 10</span> 
                                    </div>
                                    <div className="flex items-center gap-x-4 sm:mt-0">
                                        <button type='button' className="flex items-center justify-center w-1/2 px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                                            </svg>
                                        </button>
                                        <button type='button' className="flex items-center justify-center w-1/2 px-3 py-2 text-sm text-gray-700 capitalize transition-colors duration-200 bg-white border rounded-md sm:w-auto gap-x-2 hover:bg-gray-100 dark:bg-gray-900 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 rtl:-scale-x-100">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </section>
                            {/* <div>
                                    <div className='hide-on-mobile mt-3'>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                <Button className='mx-1 cbtn' appearance='' backgroundColor='#FFB020' color={color.white}>In Process</Button>
                                                <Button className='mx-1 cbtn' appearance='primary' intent='success'>Approved</Button>
                                                <Button className='mx-1 cbtn' appearance='primary' intent='danger'>Rejected</Button>
                                            </div>
                                            <div>
                                                <TextInput 
                                                    placeholder='Search'
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-12'>
                                                <Table>
                                                    <Table.Head>
                                                        <Table.TextHeaderCell flexBasis={"5%"} flexShrink={0} flexGrow={0}>No.</Table.TextHeaderCell>
                                                        <Table.TextHeaderCell flexBasis={"60%"} flexShrink={0} flexGrow={0}>Quotation</Table.TextHeaderCell>
                                                        <Table.TextHeaderCell flexBasis={"20%"} flexShrink={0} flexGrow={0}>Date Created</Table.TextHeaderCell>
                                                        <Table.TextHeaderCell flexBasis={"10%"} flexShrink={0} flexGrow={0} className='text-center'>Action</Table.TextHeaderCell>
                                                    </Table.Head>
                                                    <Table.Body>
                                                        {
                                                            loading_api && 
                                                            <Table.Row>
                                                                <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                    <Spinner size={24} color={color.gray} />
                                                                </Table.TextCell>
                                                            </Table.Row>
                                                        }
                                                        {
                                                            loading_api === false && list_quotation.length < 1 &&
                                                            <Table.Row>
                                                                <Table.TextCell flexBasis={"10%"} className='text-150'>
                                                                    <span>No data found.</span>
                                                                </Table.TextCell>
                                                            </Table.Row>
                                                        }
                                                        {
                                                            loading_api === false && list_quotation.length > 0 && list_quotation.map((data, index) =>
                                                            <Table.Row 
                                                                onClick={() => NavigateRoute(data)} 
                                                                key={index}
                                                            >
                                                                <Table.TextCell flexBasis={"5%"} className='text-150'>
                                                                    <span style={{ fontFamily: 'Poppins, sans-serif'}}>{index + 1}</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell flexBasis={"60%"} className='text-150'>
                                                                    <span style={{ fontFamily: 'Poppins, sans-serif'}} className='fw-bold'>{data.QUOTATION_NAME}</span><br />
                                                                    <span className='text-150'>• {data.QUOTATION_CODE}</span><br />
                                                                    <span className='text-150'>• {data.QUOTATION_TYPE} Request Quotation</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell flexBasis={"20%"} className='text-150'>
                                                                    <span style={{ fontFamily: 'Poppins, sans-serif'}}>{data.QDATE}</span>
                                                                </Table.TextCell>
                                                                <Table.TextCell flexBasis={"10%"} className='text-150 text-center'>
                                                                    <span style={{ fontFamily: 'Poppins, sans-serif'}}>
                                                                        <Link>
                                                                            <DocumentOpenIcon size={16} className='text-primary' />
                                                                        </Link>
                                                                    </span>
                                                                </Table.TextCell>
                                                            </Table.Row>
                                                            )
                                                        }
                                                    </Table.Body>
                                                </Table> 
                                                <div className='mt-3 d-flex justify-content-end align-items-end'>
                                                    <Pagination page={1} totalPages={1}></Pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    </TailwindBackground>
                </section>
                <Footer bg='transparent' />
            </div>
        </div>
    );
}

export default MyQuotation;
